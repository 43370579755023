import React, { useState, useEffect, useRef } from "react";
import "./industry.css";
import './servicepages.css';
import '../components/servmainsec/Servmainsec.css';
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import ProjectinMind from "../components/projectinmind/ProjectinMind";

const Education = () => {
  const [activeSection, setActiveSection] = useState("Education Overview");

  // Refs to track each section
  const overviewRef = useRef(null);
  const technologyRef = useRef(null);
  const onlineLearningRef = useRef(null);
  const dataAnalyticsRef = useRef(null);

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: overviewRef, id: "Education Overview" },
      { ref: technologyRef, id: "Role of Technology" },
      { ref: onlineLearningRef, id: "Online Learning Solutions" },
      { ref: dataAnalyticsRef, id: "Data Analytics in Education" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="industry-main">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>Education
        </h6>
        <h1>Education</h1>
        <p>
          The education sector is undergoing a revolutionary change, fueled by technology. Information technology (IT) enhances teaching methods, engages students, and facilitates communication between educators and learners, making education more accessible and effective.
        </p>
      </div>

      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Education Overview",
              "Role of Technology",
              "Online Learning Solutions",
              "Data Analytics in Education",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={overviewRef} className="section-content">
            <h2>Education Overview</h2>
            <p>
              The integration of IT in education has transformed how knowledge is imparted and acquired. With digital tools and resources, educational institutions can offer more personalized learning experiences that cater to diverse learning styles and needs.
            </p>
          </div>

          <div ref={technologyRef} className="section-content">
            <h2>Role of Technology</h2>
            <p>
              Technology plays a vital role in education by enabling innovative teaching methods, enhancing student engagement, and providing access to a wealth of information. IT solutions such as Learning Management Systems (LMS) and interactive whiteboards create dynamic learning environments that foster collaboration and creativity.
            </p>
          </div>

          <div ref={onlineLearningRef} className="section-content">
            <h2>Online Learning Solutions</h2>
            <p>
              Online learning platforms have gained significant traction, providing flexible and accessible educational opportunities. These platforms facilitate remote learning, allowing students to attend classes, access resources, and interact with peers and instructors from anywhere in the world.
            </p>
          </div>

          <div ref={dataAnalyticsRef} className="section-content">
            <h2>Data Analytics in Education</h2>
            <p>
              Data analytics is increasingly used in education to track student performance, identify trends, and enhance teaching strategies. By analyzing data, educators can make informed decisions that improve student outcomes and ensure effective resource allocation.
            </p>
          </div>
        </div>
      </div>

      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our education technology solutions are designed to empower educators and students alike, enhancing learning experiences through innovative tools and resources.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Empowering Education Through Technology</h1>
            <p>
              We specialize in creating customized educational technology solutions that meet the needs of educational institutions. Our focus is on improving engagement and learning outcomes through effective use of IT.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>Custom Learning Management Systems</h2>
            <p>
              We develop tailored Learning Management Systems that facilitate the management of educational courses, resources, and student progress, ensuring a seamless educational experience.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Interactive Learning Tools</h2>
            <p>
              Our interactive learning tools engage students and enhance their learning experience. We incorporate multimedia elements and gamification to make learning more enjoyable and effective.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Analytics and Reporting Solutions</h2>
            <p>
              We provide data analytics solutions that help educational institutions track student performance and engagement, enabling data-driven decision-making to improve educational outcomes.
            </p>
          </div>
        </div>
      </div>
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default Education;
