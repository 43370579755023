import React, { useState, useEffect, useRef } from "react";
import "./servicepages.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Servmainsec from "../components/servmainsec/Webservmainsec";
import Awards from "../components/awards/Awards";
import ProjectinMind from "../components/projectinmind/ProjectinMind";
import Wordpressservmainsec from "../components/servmainsec/Wordpressservmainsec";

const Wordpress = () => {
  const [activeSection, setActiveSection] = useState(
    "WordPress Development Process"
  );

  // Refs to track each section
  const processRef = useRef(null);
  const pluginDevelopmentRef = useRef(null);
  const themeManagementRef = useRef(null);
  const wpSecurityRef = useRef(null); // New heading related to WordPress security

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: processRef, id: "WordPress Development Process" },
      { ref: pluginDevelopmentRef, id: "Plugin Development & Optimization" },
      { ref: themeManagementRef, id: "Theme Management" },
      { ref: wpSecurityRef, id: "WordPress Security Best Practices" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Wordpressservmainsec />

      <h2 className="Serv-head">WordPress Services</h2>
      <p className="serv-desc">
        We provide cutting-edge WordPress services, covering all aspects from
        project planning to plugin development and launching secure, responsive
        websites.
      </p>
      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "WordPress Development Process",
              "Plugin Development & Optimization",
              "Theme Management",
              "WordPress Security Best Practices",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={processRef} className="section-content">
            <h2>WordPress Development Process</h2>
            <p>
              Our WordPress development process ensures a structured, systematic
              approach from project planning to website deployment. We follow
              Agile methodologies to deliver WordPress websites that meet
              business requirements and provide high performance.
            </p>
          </div>

          <div ref={pluginDevelopmentRef} className="section-content">
            <h2>Plugin Development & Optimization</h2>
            <p>
              We develop and optimize WordPress plugins to ensure high
              functionality and efficiency. Our team uses advanced techniques to
              improve plugin performance, reducing load time and increasing
              scalability.
            </p>
          </div>

          <div ref={themeManagementRef} className="section-content">
            <h2>Theme Management</h2>
            <p>
              Our theme management services include customization, optimization,
              and performance improvements. We ensure that your theme is fully
              optimized for speed, accessibility, and design.
            </p>
          </div>

          <div ref={wpSecurityRef} className="section-content">
            <h2>WordPress Security Best Practices</h2>
            <p>
              We implement the latest WordPress security best practices,
              ensuring that your website and data are protected against cyber
              threats, providing a secure WordPress ecosystem.
            </p>
          </div>
        </div>
      </div>

      <Awards />
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default Wordpress;
