import React, { useState, useEffect, useRef } from "react";
import "./servicepages.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Servmainsec from "../components/servmainsec/Webservmainsec";
import Awards from "../components/awards/Awards";
import ProjectinMind from "../components/projectinmind/ProjectinMind";
import Logoservmainsec from "../components/servmainsec/Logoservmainsec";

const LogoDesign = () => {
  const [activeSection, setActiveSection] = useState("Custom Logo Design");

  // Refs to track each section
  const customLogoRef = useRef(null);
  const brandingConsistencyRef = useRef(null);
  const versatileDesignRef = useRef(null);

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: customLogoRef, id: "Custom Logo Design" },
      { ref: brandingConsistencyRef, id: "Branding Consistency" },
      { ref: versatileDesignRef, id: "Versatile Design Formats" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Logoservmainsec />

      <h2 className="Serv-head">Logo Design Services</h2>
      <p className="serv-desc">
        We create stunning logo designs that capture the essence of your brand
        and help establish a strong visual identity in the marketplace.
      </p>
      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Custom Logo Design",
              "Branding Consistency",
              "Versatile Design Formats",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={customLogoRef} className="section-content">
            <h2>Custom Logo Design</h2>
            <p>
              Our custom logo designs are crafted with your brand's values and
              mission in mind, ensuring a unique visual identity that stands
              out.
            </p>
          </div>

          <div ref={brandingConsistencyRef} className="section-content">
            <h2>Branding Consistency</h2>
            <p>
              We help maintain branding consistency by designing logos that
              align with your overall marketing strategy, enhancing brand
              recognition.
            </p>
          </div>

          <div ref={versatileDesignRef} className="section-content">
            <h2>Versatile Design Formats</h2>
            <p>
              Our logos are delivered in multiple formats, ensuring they are
              versatile and ready for any application, from digital to print.
            </p>
          </div>
        </div>
      </div>

      <Awards />
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default LogoDesign;
