import React, { useState, useEffect, useRef } from "react";
import "./servicepages.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Servmainsec from "../components/servmainsec/Webservmainsec";
import Awards from "../components/awards/Awards";
import ProjectinMind from "../components/projectinmind/ProjectinMind";
import Videoeditservmainsec from "../components/servmainsec/Videoeditservmainsec";

const VideoEditing = () => {
  const [activeSection, setActiveSection] = useState("Video Editing Process");

  // Refs to track each section
  const processRef = useRef(null);
  const creativeEditingRef = useRef(null);
  const customizablePackagesRef = useRef(null);
  const timelyDeliveryRef = useRef(null); // New heading related to timely delivery

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: processRef, id: "Video Editing Process" },
      { ref: creativeEditingRef, id: "Creative Editing Techniques" },
      { ref: customizablePackagesRef, id: "Customizable Packages" },
      { ref: timelyDeliveryRef, id: "Timely Delivery" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Videoeditservmainsec />

      <h2 className="Serv-head">Video Editing Services</h2>
      <p className="serv-desc">
        We provide professional video editing services that elevate your content
        and engage your audience through visually stunning storytelling.
      </p>
      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Video Editing Process",
              "Creative Editing Techniques",
              "Customizable Packages",
              "Timely Delivery",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={processRef} className="section-content">
            <h2>Video Editing Process</h2>
            <p>
              Our video editing process is designed to transform your raw
              footage into polished content. We collaborate with you to
              understand your vision and ensure the final product exceeds your
              expectations.
            </p>
          </div>

          <div ref={creativeEditingRef} className="section-content">
            <h2>Creative Editing Techniques</h2>
            <p>
              We utilize cutting-edge editing techniques, including dynamic
              transitions, sound design, and color grading, to create videos
              that captivate your audience and enhance your storytelling.
            </p>
          </div>

          <div ref={customizablePackagesRef} className="section-content">
            <h2>Customizable Packages</h2>
            <p>
              Our services include a variety of customizable video editing
              packages tailored to meet the specific needs of your project,
              regardless of size or complexity.
            </p>
          </div>

          <div ref={timelyDeliveryRef} className="section-content">
            <h2>Timely Delivery</h2>
            <p>
              We pride ourselves on our punctuality. Our streamlined editing
              process ensures that your finished videos are delivered promptly,
              allowing you to meet your deadlines without stress.
            </p>
          </div>
        </div>
      </div>

      <Awards />
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default VideoEditing;
