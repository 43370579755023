import React from "react";
import "./awards.css";
import award1 from "../media/award1.png";
import award2 from "../media/goodfirms-resize-optimized.webp";
import award3 from "../media/award4.png";
import award4 from "../media/badge-top-laravel-optimized.webp";
import award5 from "../media/award3.png";
import award6 from "../media/fbd-2021-peoples-choice-award-optimized.webp";
import award7 from "../media/award2.png";
import award8 from "../media/app-healthcare-optimized.webp";

const Awards = () => {
  return (
    <>
      <div className="awards-main">
        <h1>Industry Recognition</h1>
        <div className="awards">
          <div className="award">
            <div id="award-img">
              <img src={award2} alt="" />
            </div>
            <p>
              "Proud recipient of the GoodFirms award, recognizing our
              excellence in software development and client satisfaction."
            </p>
          </div>
          <div className="award">
            <div id="award-img">
              <img src={award1} alt="" />
            </div>
            <p>
              "Honored as Top Web and App Developers, recognizing our expertise
              in delivering innovative and user-centric digital solutions."
            </p>
          </div>
          <div className="award">
            <div id="award-img">
              <img src={award6} alt="" />
            </div>
            <p>
              Winner of the People’s Choice Award, recognized as the best in web
              development for exceptional user-focused solutions.
            </p>
          </div>
        </div>
        <div className="awards2">
          <div className="award">
            <div id="award-img">
              <img src={award5} alt="" />
            </div>
            <p>
              "Honored with the Featured Member Award, recognizing our
              leadership and excellence within the development community."
            </p>
          </div>
          <div className="award">
            <div id="award-img">
              <img src={award7} alt="" />
            </div>
            <p>
              "Awarded as a Top Rated company by Software World, recognizing our
              excellence in delivering innovative software solutions."
            </p>
          </div>
          <div className="award">
            <div id="award-img">
              <img src={award8} alt="" />
            </div>
            <p>
              "Recognized with the Healthcare Developer Award for delivering
              innovative, reliable, and efficient health-tech solutions."
            </p>
          </div>
          <div className="award">
            <div id="award-img">
              <img src={award4} alt="" />
            </div>
            <p>
              "Recognized as a Top Laravel Developer, awarded for delivering
              high-quality, scalable, and efficient web solutions."
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Awards;
