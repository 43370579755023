import React from "react";
import "./industry.css";
import { Link } from "react-router-dom";

const Industry = () => {
  return (
    <>
      <div class="industry">
        <h3>INDUSTRIES</h3>
        <h6>
          Our industry-specific knowledge and digital product design expertise
          contribute to solving the right problems, creating the right products,
          and designing the products right.
        </h6>
        <div class="industry-cards">
          <Link to="/HealthCare">
            <div class="industry-card">
              <h2>
                HEALTH CARE
                <span id="industry-arrow">↗</span>
              </h2>
              <p>
                We provide cutting-edge healthcare software solutions, enhancing
                patient care, streamlining workflows, and ensuring data
                security. Additionally, we collaborate with medical startups to
                create innovative health tech products that revolutionize the
                industry.
              </p>
              <h5>Explore Industry →</h5>
            </div>
          </Link>
          <Link to="/Real_Estate">
            <div class="industry-card">
              <h2>
                REAL ESTATE
                <span id="industry-arrow">↗</span>
              </h2>
              <p>
                We offer advanced digital solutions to modernize real estate
                operations, improving property management and client engagement.
                Additionally, we partner with real estate startups to create
                innovative platforms that transform the buying and selling
                experience.
              </p>
              <h5>Explore Industry →</h5>
            </div>
          </Link>
          <Link to="/E_Commerce">
            <div class="industry-card">
              <h2>
                E-COMMERCE
                <span id="industry-arrow">↗</span>
              </h2>
              <p>
                We develop robust e-commerce platforms that enhance customer
                experiences and streamline online sales processes. Additionally,
                we work with e-commerce startups to create innovative solutions
                that transform the way products are marketed and sold online.
              </p>
              <h5>Explore Industry →</h5>
            </div>
          </Link>
          <Link to="/Education">
            <div class="industry-card">
              <h2>
                EDUCATION
                <span id="industry-arrow">↗</span>
              </h2>
              <p>
                We deliver innovative educational software that enhances
                learning experiences and improves administrative efficiency.
                Additionally, we collaborate with edtech startups to create
                cutting-edge tools that revolutionize how education is delivered
                and accessed.
              </p>
              <h5>Explore Industry →</h5>
            </div>
          </Link>
          <Link to="/Travel">
            <div class="industry-card">
              <h2>
                TRAVEL
                <span id="industry-arrow">↗</span>
              </h2>
              <p>
                We design comprehensive travel software solutions that enhance
                customer experiences and simplify booking and management
                processes. Additionally, we partner with travel startups to
                develop innovative platforms that transform the way people plan
                and enjoy their trips.
              </p>
              <h5>Explore Industry →</h5>
            </div>
          </Link>
          <Link to="/Wellness_Fitness">
            <div class="industry-card">
              <h2>
                WELLNESS & FITNESS
                <span id="industry-arrow">↗</span>
              </h2>
              <p>
                We create personalized wellness and fitness software solutions
                that help businesses improve client engagement and track
                progress effectively. Additionally, we collaborate with fitness
                startups to launch innovative apps that revolutionize health and
                wellness journeys.
              </p>
              <h5>Explore Industry →</h5>
            </div>
          </Link>
        </div>
        <div class="section4-h4">
          <Link id="industry-h4" to="/Contact">
            Become a Client →
          </Link>
        </div>
      </div>
    </>
  );
};

export default Industry;
