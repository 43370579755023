import React from "react";
import "./Servmainsec.css";

const SEOservmainsec = () => {
  return (
    <>
      <div className="servicemainsectionmain">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>SEO SERVICES
        </h6>
        <h1>SEO Services</h1>
        <p>
          Want to increase your website's visibility on search engines? Our SEO services are designed to boost your online presence and drive organic traffic to your site.
        </p>
      </div>
      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              We help you implement effective SEO strategies, including keyword optimization, technical SEO audits, and link-building efforts to improve your rankings.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Improving Search Rankings</h1>
            <p>
              Our expert team analyzes your website and industry to develop tailored SEO strategies that increase your visibility and reach more customers.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>On-Page SEO</h2>
            <p>
              We optimize your website's content, meta tags, and images to ensure search engines can easily index and rank your site.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Off-Page SEO</h2>
            <p>
              Our team helps build high-quality backlinks to improve your site's authority and relevance in the eyes of search engines.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Performance Analytics</h2>
            <p>
              We provide comprehensive SEO analytics and reports to track your performance and make data-driven improvements to your strategy.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SEOservmainsec;
