import React, { useState, useEffect, useRef } from "react";
import "./industry.css";
import './servicepages.css';
import '../components/servmainsec/Servmainsec.css';
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import ProjectinMind from "../components/projectinmind/ProjectinMind";

const Travel = () => {
  const [activeSection, setActiveSection] = useState("Industry Overview");

  // Refs to track each section
  const overviewRef = useRef(null);
  const technologyRef = useRef(null);
  const bookingSolutionsRef = useRef(null);
  const travelExperienceRef = useRef(null);

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: overviewRef, id: "Industry Overview" },
      { ref: technologyRef, id: "Role of Technology" },
      { ref: bookingSolutionsRef, id: "Booking Solutions" },
      { ref: travelExperienceRef, id: "Enhancing Travel Experience" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="industry-main">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>Travel
        </h6>
        <h1>Travel Industry</h1>
        <p>
          The travel industry is experiencing a significant transformation through the integration of technology. From planning trips to enhancing travel experiences, IT solutions are enabling seamless connectivity and improved service delivery for travelers worldwide.
        </p>
      </div>

      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Industry Overview",
              "Role of Technology",
              "Booking Solutions",
              "Enhancing Travel Experience",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={overviewRef} className="section-content">
            <h2>Industry Overview</h2>
            <p>
              The travel industry is rapidly evolving, with technology at the forefront. IT solutions are streamlining processes and enhancing customer experiences, making travel more accessible and enjoyable for everyone.
            </p>
          </div>

          <div ref={technologyRef} className="section-content">
            <h2>Role of Technology</h2>
            <p>
              Technology plays a critical role in modern travel, enabling travelers to plan, book, and manage their trips with ease. From mobile applications to AI-driven recommendations, IT solutions are transforming how people explore the world.
            </p>
          </div>

          <div ref={bookingSolutionsRef} className="section-content">
            <h2>Booking Solutions</h2>
            <p>
              Our booking solutions integrate various platforms and services, allowing travelers to compare prices, read reviews, and book accommodations and travel packages all in one place. This ensures a hassle-free booking experience.
            </p>
          </div>

          <div ref={travelExperienceRef} className="section-content">
            <h2>Enhancing Travel Experience</h2>
            <p>
              We leverage technology to enhance the overall travel experience, providing travelers with personalized recommendations, real-time updates, and virtual tours that enrich their journeys and make them more memorable.
            </p>
          </div>
        </div>
      </div>

      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our travel technology solutions focus on providing seamless experiences that cater to the needs of modern travelers, making the journey as enjoyable as the destination.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Transforming Travel with Innovative Technology</h1>
            <p>
              We specialize in developing cutting-edge solutions that streamline travel processes, enhance customer interactions, and foster unforgettable experiences for travelers around the globe.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>Travel Planning Applications</h2>
            <p>
              Our travel planning applications help users create itineraries, manage bookings, and explore destination highlights, simplifying the planning process for travelers of all kinds.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Mobile Check-In and Management</h2>
            <p>
              We offer mobile check-in solutions that allow travelers to manage their bookings and check in to flights or accommodations with ease, reducing wait times and enhancing convenience.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Customer Support and Chatbots</h2>
            <p>
              Our AI-driven chatbots provide instant customer support, assisting travelers with inquiries, booking changes, and travel advice, ensuring they receive timely assistance whenever needed.
            </p>
          </div>
        </div>
      </div>
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default Travel;
