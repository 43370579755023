import React, { useState, useEffect, useRef } from "react";
import "./industry.css";
import './servicepages.css';
import '../components/servmainsec/Servmainsec.css';
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import ProjectinMind from "../components/projectinmind/ProjectinMind";

const HealthCare = () => {
  const [activeSection, setActiveSection] = useState("Healthcare Overview");

  // Refs to track each section
  const overviewRef = useRef(null);
  const technologyRef = useRef(null);
  const patientExperienceRef = useRef(null);
  const dataManagementRef = useRef(null);

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: overviewRef, id: "Healthcare Overview" },
      { ref: technologyRef, id: "Role of Technology" },
      { ref: patientExperienceRef, id: "Enhancing Patient Experience" },
      { ref: dataManagementRef, id: "Data Management and Security" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="industry-main">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>Healthcare
        </h6>
        <h1>Healthcare</h1>
        <p>
          The healthcare industry is experiencing a transformative shift driven by technology. Information technology (IT) plays a crucial role in improving patient care, streamlining operations, and enhancing the overall healthcare experience for providers and patients alike.
        </p>
      </div>

      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Healthcare Overview",
              "Role of Technology",
              "Enhancing Patient Experience",
              "Data Management and Security",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={overviewRef} className="section-content">
            <h2>Healthcare Overview</h2>
            <p>
              The integration of IT in healthcare has led to improved patient outcomes, operational efficiency, and enhanced communication among healthcare professionals. Technologies such as electronic health records (EHRs), telemedicine, and health information systems have become essential in modern healthcare delivery.
            </p>
          </div>

          <div ref={technologyRef} className="section-content">
            <h2>Role of Technology</h2>
            <p>
              Technology in healthcare encompasses a wide range of applications, from managing patient data to improving diagnostics. IT solutions enable healthcare providers to streamline administrative processes, facilitate remote consultations, and enhance collaboration across departments. Innovations such as artificial intelligence (AI) and machine learning are transforming diagnostic procedures and patient monitoring.
            </p>
          </div>

          <div ref={patientExperienceRef} className="section-content">
            <h2>Enhancing Patient Experience</h2>
            <p>
              A patient-centered approach is at the core of modern healthcare. IT solutions enhance the patient experience by providing accessible information, online appointment scheduling, and patient portals that allow individuals to manage their health records. By prioritizing patient engagement through technology, healthcare providers can build stronger relationships with their patients and improve satisfaction levels.
            </p>
          </div>

          <div ref={dataManagementRef} className="section-content">
            <h2>Data Management and Security</h2>
            <p>
              Effective data management is crucial in healthcare for ensuring patient safety and regulatory compliance. IT plays a vital role in securely storing and sharing patient information while adhering to privacy standards such as HIPAA. Advanced analytics can provide insights into patient care and operational efficiency, allowing for continuous improvement in healthcare delivery.
            </p>
          </div>
        </div>
      </div>

      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our healthcare IT solutions are designed to empower healthcare providers through advanced technology. We focus on creating systems that enhance patient care, streamline workflows, and ensure data security.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Transforming Healthcare Through Innovation</h1>
            <p>
              We specialize in delivering customized healthcare IT solutions that address the unique challenges of the industry. Our approach combines innovative technology with best practices to enhance patient outcomes and operational efficiency.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>Custom Healthcare Solutions</h2>
            <p>
              We provide tailored IT solutions that meet the specific needs of healthcare organizations. Our team collaborates with you to design and implement systems that support your operational goals and enhance patient care.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Secure Health Information Systems</h2>
            <p>
              Our systems are designed with a focus on security, ensuring that patient data is protected against unauthorized access and breaches. We prioritize compliance with healthcare regulations to maintain the integrity of sensitive information.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Continuous Support and Maintenance</h2>
            <p>
              We offer comprehensive support and maintenance services to ensure that your healthcare IT systems operate efficiently. Our dedicated team is available to address any issues and provide updates as needed.
            </p>
          </div>
        </div>
      </div>
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default HealthCare;
