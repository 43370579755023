import React from "react";
import "./Servmainsec.css";

const Digitalmservmain = () => {
  return (
    <>
      <div className="servicemainsectionmain">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>DIGITAL MARKETING
        </h6>
        <h1>Digital Marketing</h1>
        <p>
          Looking to elevate your online presence? Our digital marketing services combine creativity and data-driven strategies to enhance your brand visibility and engage your target audience effectively.
        </p>
      </div>
      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our digital marketing services are designed to build brand awareness and drive traffic to your website through tailored strategies that resonate with your audience.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Maximizing Your Online Impact</h1>
            <p>
              We specialize in creating comprehensive digital marketing strategies that leverage SEO, content marketing, and social media to maximize your online impact and connect with potential customers.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>Targeted Campaigns</h2>
            <p>
              Our team crafts targeted digital marketing campaigns to reach your ideal customers, utilizing tools and analytics to ensure your message hits home.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Engaging Content Creation</h2>
            <p>
              We create compelling content that engages your audience and drives conversions, focusing on storytelling that aligns with your brand identity.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Performance Tracking</h2>
            <p>
              We provide detailed performance tracking and analytics to assess the effectiveness of your digital marketing strategies, allowing for continuous optimization and improvement.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Digitalmservmain;
