import React, { useState, useEffect, useRef } from "react";
import "./servicepages.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Servmainsec from "../components/servmainsec/Webservmainsec";
import Awards from "../components/awards/Awards";
import ProjectinMind from "../components/projectinmind/ProjectinMind";
import AIservmainsec from "../components/servmainsec/AIservmainsec";

const Services = () => {
  const [activeSection, setActiveSection] = useState("AI Development Process");

  // Refs to track each section
  const processRef = useRef(null);
  const modelTrainingRef = useRef(null);
  const dataManagementRef = useRef(null);
  const aiSecurityRef = useRef(null); // New heading related to AI security

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: processRef, id: "AI Development Process" },
      { ref: modelTrainingRef, id: "Model Training & Optimization" },
      { ref: dataManagementRef, id: "Data Management" },
      { ref: aiSecurityRef, id: "AI Security Best Practices" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <AIservmainsec />

      <h2 className="Serv-head">AI & ML Services</h2>
      <p className="serv-desc">
        We provide cutting-edge AI & ML services, covering all aspects from
        project planning to training models and launching intelligent, secure
        AI-driven systems.
      </p>
      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "AI Development Process",
              "Model Training & Optimization",
              "Data Management",
              "AI Security Best Practices",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={processRef} className="section-content">
            <h2>AI Development Process</h2>
            <p>
              Our AI development process ensures a structured, systematic
              approach from project planning to model deployment. We follow
              Agile methodologies to deliver AI systems that meet business
              requirements and provide high performance.
            </p>
          </div>

          <div ref={modelTrainingRef} className="section-content">
            <h2>Model Training & Optimization</h2>
            <p>
              We train and optimize machine learning models to ensure high
              accuracy and efficiency. Our team uses advanced techniques to
              improve model performance, reducing training time and increasing
              scalability.
            </p>
          </div>

          <div ref={dataManagementRef} className="section-content">
            <h2>Data Management</h2>
            <p>
              Our data management services include preprocessing, data
              cleansing, and organization. We ensure that your data is optimized
              for machine learning models, leading to better predictions and
              results.
            </p>
          </div>

          <div ref={aiSecurityRef} className="section-content">
            <h2>AI Security Best Practices</h2>
            <p>
              We implement the latest AI security best practices, ensuring that
              your models and data are protected against adversarial attacks and
              other threats, providing a secure AI ecosystem.
            </p>
          </div>
        </div>
      </div>

      <Awards />
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default Services;
