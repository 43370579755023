import React, { useState, useEffect, useRef } from "react";
import "./servicepages.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Faq from "../components/faqs/Faq";
import Servmainsec from "../components/servmainsec/Webservmainsec";
import Awards from "../components/awards/Awards";
import ProjectinMind from "../components/projectinmind/ProjectinMind";

const Services = () => {
  const [activeSection, setActiveSection] = useState("Development Process");

  // Refs to track each section
  const processRef = useRef(null);
  const responsiveRef = useRef(null);
  const maintenanceRef = useRef(null);
  const securityRef = useRef(null); // New heading related to web security

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: processRef, id: "Development Process" },
      { ref: responsiveRef, id: "Responsive Design" },
      { ref: maintenanceRef, id: "Website Maintenance" },
      { ref: securityRef, id: "Web Security Best Practices" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Servmainsec />

      <h2 className="Serv-head">Services</h2>
      <p className="serv-desc">
        We provide high-quality web development services, covering all aspects
        from planning and designing to launching secure, responsive websites.
      </p>
      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Development Process",
              "Responsive Design",
              "Website Maintenance",
              "Web Security Best Practices",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={processRef} className="section-content">
            <h2>Development Process</h2>
            <p>
              Our development process ensures a structured, systematic approach
              from project planning to deployment. We follow Agile methodologies
              to deliver websites that meet business requirements and deliver
              high performance.
            </p>
          </div>

          <div ref={responsiveRef} className="section-content">
            <h2>Responsive Design</h2>
            <p>
              We create responsive designs that adapt to various devices and
              screen sizes. This ensures that your website provides an optimal
              user experience on desktops, tablets, and smartphones.
            </p>
          </div>

          <div ref={maintenanceRef} className="section-content">
            <h2>Website Maintenance</h2>
            <p>
              Our maintenance services include regular updates, security
              patches, and performance enhancements. We ensure your website
              remains up-to-date and running smoothly.
            </p>
          </div>

          <div ref={securityRef} className="section-content">
            <h2>Web Security Best Practices</h2>
            <p>
              We implement the latest web security best practices, ensuring your
              website is protected against common threats like malware and
              hacking attempts, providing a secure experience for users.
            </p>
          </div>
        </div>
      </div>

      <Awards />
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default Services;
