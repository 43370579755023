import React from "react";
import "./Servmainsec.css";

const Servmainsec = () => {
  return (
    <>
      <div className="servicemainsectionmain">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>APP DEVELOPMENT
        </h6>
        <h1>App Development</h1>
        <p>
          Looking to scale your development efforts seamlessly or build a
          cutting-edge solution from the ground up? We provide comprehensive app
          development services to ensure your product is future-ready.
        </p>
      </div>
      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our team delivers tailored app development solutions, ensuring
              scalability, reliability, and top performance for your business.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Enhancing the Digital Customer Experience</h1>
            <p>
              We specialize in creating mobile applications that elevate the
              customer journey. From intuitive user interfaces to seamless
              backend integrations, our development process is designed to meet
              both user expectations and business objectives.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>CRO-Focused UX Design</h2>
            <p>
             Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, eveniet unde asperiores harum facilis possimus magni aliquid veniam? Magni, qui maiores quaerat laborum quod perferendis consequatur deleniti aspernatur delectus, veniam, odit libero mollitia doloribus ullam quas iste debitis sapiente error vitae. Tenetur, expedita!
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Custom Mobile App Development</h2>
            <p>
             Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eaque fuga corrupti rem voluptates quas facilis iure eum sit! Fuga deleniti corrupti nam magnam! Harum, expedita, autem id, nulla tempore nesciunt ab pariatur repellat reiciendis voluptatum quisquam! Expedita quidem praesentium sit aliquid fuga maxime.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Enterprise-Level Mobile Apps</h2>
            <p>
             Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit corporis excepturi libero harum sed sit suscipit eos modi. Id accusantium in officia libero autem veniam. Quia error quod fugit aperiam, eligendi voluptatum deleniti culpa nesciunt, earum dignissimos iure! Excepturi architecto repellat repellendus nemo?
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Servmainsec;
