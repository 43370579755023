import React from "react";
import "./homemain.css";
import Beyond from "../media/Beyond.png";
import Collaghan from "../media/Callaghan.png";
import Mundi from "../media/mundipharma.png";
import Twedales from "../media/twedales.png";
import Pakverum from "../media/pakverum.png";
import FlightCoffee from "../media/flightcoffee.png";

const homemain = () => {
  return (
    <div>
      <div class="section1">
        <div class="section1-main fdc">
          <h2>
            Web | Product Design | Development Agency&nbsp;/
            <span id="lightslash">//</span>
            Los Angeles
          </h2>
          <h1 id="sec1-head">Revolutionize Bussinesses</h1>
          <h1>With Our Design Agency</h1>
          <h3>
            We Launch <span>MVPs</span> redefine
            <span> Existing products</span> & Continuously dive into
            <span> Customer development</span>
          </h3>
          <div id="moving-div">
            <div class="blur-left"></div>
            <div class="move">
              <img src={Beyond} alt="" />
              <img src={Collaghan} alt="" />
              <img src={Mundi} alt="" />
              <img src={Twedales} alt="" />
              <img src={Pakverum} alt="" />
              <img src={FlightCoffee} alt="" />
            </div>
            <div class="move">
              <img src={Beyond} alt="" />
              <img src={Collaghan} alt="" />
              <img src={Mundi} alt="" />
              <img src={Twedales} alt="" />
              <img src={Pakverum} alt="" />
              <img src={FlightCoffee} alt="" />
            </div>
            <div class="blur-right"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default homemain;
