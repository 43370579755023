import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div class="footer-top">
          <div class="footer-top-left">
            <Link to="/Contact">
              <h1>Have a project</h1>
              <h1>in mind?</h1>
              <h1>Contact Us →</h1>
            </Link>
            <div className="footer-top-left-bottom">
              <div className="footer-top-left-bottom-left">
                <h2>Services</h2>
                <h3>
                  <Link to="/Webdevelopment">Web Development</Link>
                </h3>
                <h3>
                  <Link to="/Appdevelopment">APP Development</Link>
                </h3>
                <h3>
                  <Link to="/AI_ML">AI & ML</Link>
                </h3>
                <h3>
                  <Link to="/SocialMediaMarketingandManagement">
                    Social Media Management
                  </Link>
                </h3>
                <h3>
                  <Link to="/VideoEditing">Video Editing</Link>
                </h3>
                <h3>
                  <Link to="/UI_UX">UI & UX</Link>
                </h3>
              </div>
              <div className="footer-top-left-bottom-left">
                <h2>Industries</h2>
                <h3>
                  <Link to="/Real_Estate">Real-Estate</Link>
                </h3>
                <h3>
                  <Link to="/HealthCare">Health-Care</Link>
                </h3>
                <h3>
                  <Link to="/Education">Education</Link>
                </h3>
                <h3>
                  <Link to="/E_Commerce">E-Commerce</Link>
                </h3>
                <h3>
                  <Link to="/Travel">Travel</Link>
                </h3>
                <h3>
                  <Link to="/Wellness_Fitness">Wellness & Fitness</Link>
                </h3>
              </div>
              <div className="footer-top-left-bottom-left">
                <h2>Quick Links</h2>
                <h3>
                  <Link to="/About">About</Link>
                </h3>
                <h3>
                  <Link to="/Contact">Contact</Link>
                </h3>
              </div>
            </div>
          </div>
          <div className="footer-top-right">
            <h2>Our Location</h2>
            <h3 id="address">
              606 Redwood Boulevard, Floor 7, Los Angeles, CA 90017, United
              States.
            </h3>
            <h3 id="address">
              101 Wellington Street, 6th Floor, Office 220, Ottawa, ON K1A 0A4,
              Canada.
            </h3>
            <h2>Email</h2>
            <h3>contact@ustechinnovations.agency</h3>
            <h2>VISIT US</h2>
            <h3>
              MONDAY - FRIDAY <span>10:00 AM - 6:00 PM</span>
            </h3>
            <div class="socialmedia">
              <h2>Talk to Us</h2>
              <div class="social-names">
                <a
                  href="https://wa.me/13863193387?text=Hello%20I%20would%20like%20to%20inquire%20about%20your%20services"
                  target="_blank"
                >
                  <li>+1 (386) 319-3387</li>
                </a>

                {/* <li>FACEBOOK</li>
                <li>TWITTER</li>
                <li>INSTAGRAM</li> */}
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <h2>
            US Tech Innovations | Web + Product Development and Design Agency.
          </h2>
          <h2>All Rights Reserved ©2024</h2>
        </div>
      </footer>
    </>
  );
};

export default Footer;
