// import "./App.css";
import { BrowserRouter, Routes, Route, HashRouter, } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import WebDevelopment from "./pages/WebDevelopment";
import AppDevelopment from "./pages/AppDevelopment";
import AI_ML from "./pages/AI_ML";
import Wordpress from "./pages/Wordpress";
import Ui_Ux from "./pages/Ui_Ux";
import VideoEditing from "./pages/VideoEditing";
import DigitalMarketing from "./pages/DigitalMarketing";
import LogoDesign from "./pages/LogoDesign";
import SocialMediaMarketingandManagement from "./pages/SocialMediaMarketingandManagement";
import Adsense from "./pages/Adsense";
import SEO from "./pages/SEO";
import ECommerce from "./pages/ECommerce";
import HealthCare from "./pages/HealthCare";
import Education from "./pages/Education";
import RealEstate from "./pages/RealEstate";
import WellnessFitness from "./pages/Wellness&Fitness";
import Travel from "./pages/Travel";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Webdevelopment" element={<WebDevelopment />} />
          <Route path="/Appdevelopment" element={<AppDevelopment />} />
          <Route path="/AI_ML" element={<AI_ML />} />
          <Route path="/Wordpress" element={<Wordpress />} />
          <Route path="/UI_UX" element={<Ui_Ux />} />
          <Route path="/VideoEditing" element={<VideoEditing />} />
          <Route path="/DigitalMarketing" element={<DigitalMarketing />} />
          <Route path="/LogoDesign" element={<LogoDesign />} />
          <Route path="/Adsense" element={<Adsense />} />
          <Route path="/SocialMediaMarketingandManagement" element={<SocialMediaMarketingandManagement />} />
          <Route path="/SEO" element={<SEO />} />
          <Route path="/E_Commerce" element={<ECommerce />} />
          <Route path="/HealthCare" element={<HealthCare />} />
          <Route path="/Education" element={<Education />} />
          <Route path="/Real_Estate" element={<RealEstate />} />
          <Route path="/Wellness_Fitness" element={<WellnessFitness />} />
          <Route path="/Travel" element={<Travel />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
