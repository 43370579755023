import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./services.css";

const StrategyWorkshops = () => {
  const [activeSection, setActiveSection] = useState("Web Development");

  // Refs to track each section
  const webDevRef = useRef(null);
  const appDevRef = useRef(null);
  const aiMlRef = useRef(null);
  const uiux = useRef(null);
  const wpDevRef = useRef(null);
  const seORef = useRef(null);
  const videoEditRef = useRef(null);
  const logoDesignRef = useRef(null);
  const socialMediaRef = useRef(null);
  const googleAdsRef = useRef(null);
  const digitalMarketingRef = useRef(null);

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: webDevRef, id: "Web Development" },
      { ref: appDevRef, id: "App Development" },
      { ref: aiMlRef, id: "AI & ML" },
      { ref: uiux, id: "UI & UX Design" },
      { ref: wpDevRef, id: "Wordpress Development" },
      { ref: seORef, id: "SEO" },
      { ref: videoEditRef, id: "Video Editing" },
      { ref: logoDesignRef, id: "Logo Designs" },
      { ref: socialMediaRef, id: "Social Media Marketing and Management" },
      { ref: googleAdsRef, id: "Google Adsense Services" },
      { ref: digitalMarketingRef, id: "Digital Marketing Service" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <h2 className="Services-head">SERVICES</h2>
      <p className="services-desc">
        At US Tech Innovations, we offer a comprehensive range of services
        tailored to meet the unique needs of our clients. Our expert team
        specializes in innovative solutions, ensuring your business stands out
        in the competitive digital landscape.
      </p>
      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Web Development",
              "App Development",
              "AI & ML",
              "UI & UX Design",
              "Wordpress Development",
              "SEO",
              "Video Editing",
              "Logo Designs",
              "Social Media Marketing and Management",
              "Google Adsense Services",
              "Digital Marketing Service",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={webDevRef} className="section-content">
            <h2>Web Development</h2>
            <p>
              We offer top-notch web development services, crafting dynamic,
              responsive websites using the latest technologies like React,
              Angular, and Vue.js. Our websites are optimized for performance,
              usability, and SEO to help businesses achieve their goals online.
            </p>
            <Link to="/webdevelopment" className="explore-link">
              <p>Explore Service →</p>
            </Link>
          </div>

          <div ref={appDevRef} className="section-content">
            <h2>App Development</h2>
            <p>
              We build intuitive and high-performance mobile applications using
              React Native, Flutter, and native technologies. Our apps work
              seamlessly across platforms and devices, ensuring a smooth user
              experience while catering to your business needs.
            </p>
            <Link to="/AppDevelopment" className="explore-link">
              <p>Explore Service →</p>
            </Link>
          </div>

          <div ref={aiMlRef} className="section-content">
            <h2>AI & ML</h2>
            <p>
              Harness the power of AI and machine learning to enhance your
              business operations. We develop AI-driven solutions for predictive
              analytics, automation, and data analysis, delivering smarter,
              data-informed decisions to increase efficiency and drive growth.
            </p>
            <Link to="/AI_ML" className="explore-link">
              <p>Explore Service →</p>
            </Link>
          </div>

          <div ref={uiux} className="section-content">
            <h2>UI & UX Design</h2>
            <p>
              We create intuitive and visually appealing designs that enhance
              user interaction and functionality. Our approach ensures seamless
              experiences across all platforms, focusing on user satisfaction
              and business results. From wireframes to prototypes, we deliver
              optimized solutions tailored to your needs.
            </p>
            <Link to="/UI_UX" className="explore-link">
              <p>Explore Service →</p>
            </Link>
          </div>

          <div ref={wpDevRef} className="section-content">
            <h2>Wordpress Development</h2>
            <p>
              Our WordPress development services include custom themes, plugins,
              and website management. We help create feature-rich, user-friendly
              websites that are easy to manage and scalable to your growing
              business needs while maintaining excellent performance.
            </p>
            <Link to="/Wordpress" className="explore-link">
              <p>Explore Service →</p>
            </Link>
          </div>

          <div ref={seORef} className="section-content">
            <h2>SEO</h2>
            <p>
              We employ data-driven SEO strategies to enhance your website's
              visibility and ranking on search engines. Our focus includes
              optimizing content, keywords, and technical elements to drive
              organic traffic and improve site authority. Tailored solutions
              ensure sustainable growth and a stronger online presence.
            </p>
            <Link to="/SEO" className="explore-link">
              <p>Explore Service →</p>
            </Link>
          </div>

          <div ref={videoEditRef} className="section-content">
            <h2>Video Editing</h2>
            <p>
              We offer professional video editing services that transform raw
              footage into engaging videos for marketing, social media, and
              more. Our expertise includes animation, special effects, and sound
              design to create visually stunning content.
            </p>
            <Link to="/VideoEditing" className="explore-link">
              <p>Explore Service →</p>
            </Link>
          </div>

          <div ref={logoDesignRef} className="section-content">
            <h2>Logo Designs</h2>
            <p>
              We create custom, high-quality logos that perfectly represent your
              brand. Our logos are designed to be versatile, memorable, and
              aligned with your business identity, ensuring your brand stands
              out in a competitive market.
            </p>
            <Link to="/LogoDesign" className="explore-link">
              <p>Explore Service →</p>
            </Link>
          </div>

          <div ref={socialMediaRef} className="section-content">
            <h2>Social Media Marketing and Management</h2>
            <p>
              Our social media marketing and management services help boost your
              online presence, grow your audience, and drive engagement. We
              create tailored strategies, manage campaigns, and analyze
              performance across multiple platforms to maximize your brand’s
              impact.
            </p>
            <Link
              to="/SocialMediaMarketingandManagement"
              className="explore-link"
            >
              <p>Explore Service →</p>
            </Link>
          </div>

          <div ref={googleAdsRef} className="section-content">
            <h2>Google Adsense Services</h2>
            <p>
              We provide expert Google AdSense management services, optimizing
              ad placements, layouts, and targeting to maximize revenue. Our
              strategies focus on improving click-through rates (CTR) and
              ensuring you get the best returns from your AdSense campaigns.
            </p>
            <Link to="/Adsense" className="explore-link">
              <p>Explore Service →</p>
            </Link>
          </div>

          <div ref={digitalMarketingRef} className="section-content">
            <h2>Digital Marketing Service</h2>
            <p>
              Our comprehensive digital marketing services cover SEO, PPC,
              content marketing, and more. We create personalized strategies
              designed to improve your online visibility, drive targeted
              traffic, and increase conversions to help your business thrive in
              the digital space.
            </p>
            <Link to="DigitalMarketing" className="explore-link">
              <p>Explore Service →</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default StrategyWorkshops;
