import React from "react";
import Navbar from "../components/navbar/Navbar";
import Homemain from "../components/homemain/homemain";
import Experiencecount from "../components/experiencecount/Experiencecount";
// import Services from "../components/services/Servicessection";
import Testimonials from "../components/testimonials/Testimonials";
import Faq from "../components/faqs/Faq";
import Footer from "../components/footer/Footer";
// import Servicessection from "../components/services/Servicessection";
// import StrategyWorkshops from "../components/services/Services";
import Industry from "../components/industry/Industry";
import Services from "../components/services/Services";
import Awards from "../components/awards/Awards";
import ProjectinMind from "../components/projectinmind/ProjectinMind";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Homemain />
      <Experiencecount />
      <Industry/>
      <Services/>
      <Testimonials />
      <Faq/>
      <ProjectinMind/>
      <Footer/>
    </div>
  );
};

export default Home;
