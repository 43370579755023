import React from "react";
import "./Servmainsec.css";

const Logoservmainsec = () => {
  return (
    <>
      <div className="servicemainsectionmain">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>LOGO DESIGN
        </h6>
        <h1>Logo Design</h1>
        <p>
          Looking to create a unique brand identity? Our logo design services focus on crafting memorable logos that effectively communicate your brand's essence and values.
        </p>
      </div>
      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our logo design services are tailored to meet your brand's specific needs, ensuring a distinct visual representation that resonates with your audience.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Crafting Visual Identities</h1>
            <p>
              We specialize in creating captivating logo designs that embody your brand's personality and create a lasting impression on your target market.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>Custom Logo Design</h2>
            <p>
              Our team designs custom logos that reflect your brand's mission and values, setting you apart in a competitive marketplace.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Branding Consistency</h2>
            <p>
              We ensure that your logo aligns with your overall branding strategy, creating a cohesive visual identity across all platforms and materials.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Versatile Design Formats</h2>
            <p>
              Our logo designs are delivered in various formats, ensuring versatility for both digital and print applications to meet all your branding needs.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logoservmainsec;
