import React, { useState } from "react";
import "./faq.css";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h1 id="faq-head">Frequently Asked Questions</h1>

      <div className={`faq-item ${activeIndex === 0 ? "active" : ""}`}>
        <button className="faq-question" onClick={() => toggleFAQ(0)}>
          /01 How can your full-cycle web and app development services accelerate digital transformation for my business?
          <span className="toggle-icon">{activeIndex === 0 ? "▼" : "▲"}</span>
        </button>
        <div className="faq-answer">
          <p>
            We provide end-to-end web and app development solutions that align with your business strategy. From conceptualization and UI/UX design to backend integration and post-launch support, we ensure that your digital products meet evolving market demands. Our agile methodology enables us to pivot quickly, ensuring faster time-to-market while maintaining flexibility for iterative improvements.
          </p>
        </div>
      </div>

      <div className={`faq-item ${activeIndex === 1 ? "active" : ""}`}>
        <button className="faq-question" onClick={() => toggleFAQ(1)}>
          /02 How does your UI/UX design approach enhance customer engagement and user retention?
          <span className="toggle-icon">{activeIndex === 1 ? "▼" : "▲"}</span>
        </button>
        <div className="faq-answer">
          <p>
            Our UI/UX design process focuses on creating intuitive, user-centered experiences that drive higher engagement and conversion rates. We conduct thorough user research, prototype designs, and usability testing to ensure the interface is both aesthetically pleasing and functional, optimizing the entire user journey across devices and platforms.
          </p>
        </div>
      </div>

      <div className={`faq-item ${activeIndex === 2 ? "active" : ""}`}>
        <button className="faq-question" onClick={() => toggleFAQ(2)}>
          /03 What social media marketing strategies do you implement to boost brand visibility and customer acquisition?
          <span className="toggle-icon">{activeIndex === 2 ? "▼" : "▲"}</span>
        </button>
        <div className="faq-answer">
          <p>
            We leverage data-driven social media marketing strategies tailored to your industry and audience. Our team utilizes targeted campaigns, influencer collaborations, and advanced analytics to increase brand visibility and drive organic growth. We focus on building meaningful engagement, enhancing customer acquisition, and ensuring that every dollar spent delivers a measurable return on investment (ROI).
          </p>
        </div>
      </div>

      <div className={`faq-item ${activeIndex === 3 ? "active" : ""}`}>
        <button className="faq-question" onClick={() => toggleFAQ(3)}>
          /04 How do your AI and machine learning solutions provide actionable insights and competitive advantage for businesses?
          <span className="toggle-icon">{activeIndex === 3 ? "▼" : "▲"}</span>
        </button>
        <div className="faq-answer">
          <p>
            Our AI and ML solutions are designed to turn data into actionable insights. Whether it’s predictive analytics, customer behavior modeling, or automation of workflows, we implement custom AI models to help businesses optimize operations, improve decision-making, and deliver personalized experiences that give you a competitive edge in your industry.
          </p>
        </div>
      </div>

      <div className={`faq-item ${activeIndex === 4 ? "active" : ""}`}>
        <button className="faq-question" onClick={() => toggleFAQ(4)}>
          /05 How do your video editing and branding services enhance marketing efforts across digital channels?
          <span className="toggle-icon">{activeIndex === 4 ? "▼" : "▲"}</span>
        </button>
        <div className="faq-answer">
          <p>
            Our video editing and branding services focus on creating visually impactful content that resonates with your target audience. From explainer videos to promotional campaigns, we ensure consistent brand messaging across platforms. Our creative team handles everything from scripting to post-production, maximizing viewer engagement and driving your marketing objectives.
          </p>
        </div>
      </div>

      <div className={`faq-item ${activeIndex === 5 ? "active" : ""}`}>
        <button className="faq-question" onClick={() => toggleFAQ(5)}>
          /06 How do you incorporate modern design trends into your logo design process to ensure long-lasting brand identity?
          <span className="toggle-icon">{activeIndex === 5 ? "▼" : "▲"}</span>
        </button>
        <div className="faq-answer">
          <p>
            Our logo design process begins with an in-depth understanding of your brand’s values, target audience, and market positioning. We combine current design trends with timeless branding principles to craft logos that not only capture attention but also establish a strong visual identity that can evolve with your business over time.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faq;
