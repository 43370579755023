import React, { useState, useEffect, useRef } from "react";
import "./industry.css";
import './servicepages.css';
import '../components/servmainsec/Servmainsec.css';
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import ProjectinMind from "../components/projectinmind/ProjectinMind";

const ECommerce = () => {
  const [activeSection, setActiveSection] = useState("E-Commerce Overview");

  // Refs to track each section
  const overviewRef = useRef(null);
  const technologyRef = useRef(null);
  const customerExperienceRef = useRef(null);
  const analyticsRef = useRef(null);

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: overviewRef, id: "E-Commerce Overview" },
      { ref: technologyRef, id: "Role of Technology" },
      { ref: customerExperienceRef, id: "Enhancing Customer Experience" },
      { ref: analyticsRef, id: "Data Analytics" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="industry-main">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>E-Commerce
        </h6>
        <h1>E-Commerce</h1>
        <p>
          The e-commerce industry has revolutionized retail and service sectors by enabling businesses to reach customers globally. IT serves as the backbone of e-commerce, powering online transactions, enhancing user experience, and providing analytical insights to drive business growth.
        </p>
      </div>

      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "E-Commerce Overview",
              "Role of Technology",
              "Enhancing Customer Experience",
              "Data Analytics",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={overviewRef} className="section-content">
            <h2>E-Commerce Overview</h2>
            <p>
              E-commerce encompasses buying and selling goods and services over the internet. The integration of IT in e-commerce has streamlined operations, allowing businesses to manage inventory, process orders, and engage with customers efficiently. Online platforms provide businesses with tools to establish their brand, expand market reach, and create a personalized shopping experience.
            </p>
          </div>

          <div ref={technologyRef} className="section-content">
            <h2>Role of Technology</h2>
            <p>
              Technology plays a critical role in the e-commerce ecosystem. From cloud computing to mobile applications, IT solutions enable businesses to operate seamlessly and remain competitive. Payment gateways, shopping cart software, and customer relationship management (CRM) systems are essential for processing transactions and maintaining customer relationships. The use of artificial intelligence (AI) and machine learning enhances product recommendations, optimizing sales potential.
            </p>
          </div>

          <div ref={customerExperienceRef} className="section-content">
            <h2>Enhancing Customer Experience</h2>
            <p>
              A successful e-commerce platform prioritizes customer experience. IT solutions facilitate user-friendly interfaces, enabling easy navigation and a seamless checkout process. Features like live chat support, personalized marketing, and customer reviews enhance engagement and build trust. By utilizing customer data and feedback, businesses can continuously refine their offerings to meet evolving consumer expectations.
            </p>
          </div>

          <div ref={analyticsRef} className="section-content">
            <h2>Data Analytics</h2>
            <p>
              Data analytics is essential for e-commerce success. By analyzing customer behavior, purchase patterns, and market trends, businesses can make informed decisions and implement effective marketing strategies. IT tools provide insights that help in inventory management, sales forecasting, and targeted advertising, enabling businesses to maximize ROI and enhance overall performance.
            </p>
          </div>
        </div>
      </div>

      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our e-commerce solutions are designed to empower businesses by leveraging technology to create impactful online experiences. We focus on developing platforms that are not only functional but also visually appealing to engage customers effectively.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Empowering E-Commerce Success</h1>
            <p>
              We specialize in creating customized e-commerce platforms that meet the unique needs of our clients. Our approach combines innovative technology with a deep understanding of market trends to deliver solutions that drive sales and customer satisfaction.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>Custom E-Commerce Solutions</h2>
            <p>
              We provide tailored e-commerce solutions that align with your business goals. Our team works closely with you to understand your needs and develop a platform that enhances your brand presence online.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Integrated Payment Systems</h2>
            <p>
              Our platforms integrate secure and reliable payment systems, ensuring smooth transactions for your customers. We prioritize security and compliance to safeguard sensitive information.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Comprehensive Support</h2>
            <p>
              We offer ongoing support and maintenance services to ensure your e-commerce platform runs smoothly. Our team is dedicated to resolving any issues promptly and enhancing your platform's performance.
            </p>
          </div>
        </div>
      </div>
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default ECommerce;
