import React, { useState, useEffect, useRef } from "react";
import "./servicepages.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Servmainsec from "../components/servmainsec/Webservmainsec";
import Awards from "../components/awards/Awards";
import ProjectinMind from "../components/projectinmind/ProjectinMind";
import Digitalmservmain from "../components/servmainsec/Digitalmservmainsec";

const DigitalMarketing = () => {
  const [activeSection, setActiveSection] = useState(
    "Digital Marketing Strategy"
  );

  // Refs to track each section
  const strategyRef = useRef(null);
  const targetedCampaignsRef = useRef(null);
  const engagingContentRef = useRef(null);
  const performanceTrackingRef = useRef(null); // New heading related to performance tracking

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: strategyRef, id: "Digital Marketing Strategy" },
      { ref: targetedCampaignsRef, id: "Targeted Campaigns" },
      { ref: engagingContentRef, id: "Engaging Content Creation" },
      { ref: performanceTrackingRef, id: "Performance Tracking" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Digitalmservmain />

      <h2 className="Serv-head">Digital Marketing Services</h2>
      <p className="serv-desc">
        We provide innovative digital marketing solutions that drive traffic,
        increase engagement, and enhance your brand's online visibility.
      </p>
      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Digital Marketing Strategy",
              "Targeted Campaigns",
              "Engaging Content Creation",
              "Performance Tracking",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={strategyRef} className="section-content">
            <h2>Digital Marketing Strategy</h2>
            <p>
              Our digital marketing strategy is tailored to meet your unique
              business goals, combining research, insights, and creativity to
              drive measurable results.
            </p>
          </div>

          <div ref={targetedCampaignsRef} className="section-content">
            <h2>Targeted Campaigns</h2>
            <p>
              We design and execute targeted campaigns that effectively reach
              your desired audience, utilizing various channels and tactics to
              maximize engagement and conversion rates.
            </p>
          </div>

          <div ref={engagingContentRef} className="section-content">
            <h2>Engaging Content Creation</h2>
            <p>
              Our content creation process focuses on producing engaging,
              high-quality content that resonates with your audience and
              strengthens your brand identity.
            </p>
          </div>

          <div ref={performanceTrackingRef} className="section-content">
            <h2>Performance Tracking</h2>
            <p>
              We implement robust performance tracking systems to monitor the
              effectiveness of your marketing efforts, providing insights that
              allow for data-driven adjustments and improvements.
            </p>
          </div>
        </div>
      </div>

      <Awards />
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default DigitalMarketing;
