import React, { useState, useEffect, useRef } from "react";
import "./servicepages.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Servmainsec from "../components/servmainsec/Webservmainsec";
import Awards from "../components/awards/Awards";
import ProjectinMind from "../components/projectinmind/ProjectinMind";
import Socialmservmainsec from "../components/servmainsec/Socialmservmainse";

const SocialMediaMarketingandManagement = () => {
  const [activeSection, setActiveSection] = useState("Content Creation");

  // Refs to track each section
  const contentCreationRef = useRef(null);
  const communityManagementRef = useRef(null);
  const analyticsReportingRef = useRef(null);

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: contentCreationRef, id: "Content Creation" },
      { ref: communityManagementRef, id: "Community Management" },
      { ref: analyticsReportingRef, id: "Analytics and Reporting" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Socialmservmainsec />

      <h2 className="Serv-head">
        Social Media Marketing and Management Services
      </h2>
      <p className="serv-desc">
        Our social media marketing and management services are designed to
        elevate your brand presence and engage with your audience effectively
        across multiple platforms.
      </p>
      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Content Creation",
              "Community Management",
              "Analytics and Reporting",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={contentCreationRef} className="section-content">
            <h2>Content Creation</h2>
            <p>
              Our team creates high-quality, engaging content tailored to your
              brand’s voice, ensuring it resonates with your audience and
              enhances your online presence.
            </p>
          </div>

          <div ref={communityManagementRef} className="section-content">
            <h2>Community Management</h2>
            <p>
              We actively manage your social media communities, fostering
              engagement and responding to inquiries to maintain a positive
              brand image.
            </p>
          </div>

          <div ref={analyticsReportingRef} className="section-content">
            <h2>Analytics and Reporting</h2>
            <p>
              Our analytics and reporting services provide insights into your
              social media performance, helping you make informed decisions for
              future campaigns.
            </p>
          </div>
        </div>
      </div>
      <Awards />
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default SocialMediaMarketingandManagement;
