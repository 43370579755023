import React from "react";
import "./Servmainsec.css";

const AIservmainsec = () => {
  return (
    <>
      <div className="servicemainsectionmain">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>AI & ML SERVICES
        </h6>
        <h1>AI & ML Development</h1>
        <p>
          Looking to integrate intelligent systems into your business or build a
          cutting-edge AI-driven solution? We provide comprehensive AI and
          machine learning services to ensure your product is future-ready.
        </p>
      </div>
      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our team delivers tailored AI and ML solutions, ensuring
              scalability, reliability, and high performance for your business.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Transforming Customer Experience with AI</h1>
            <p>
              We specialize in developing AI-powered applications that elevate
              the customer journey. From intuitive AI-driven interfaces to
              seamless machine learning integrations, our process is designed to
              meet both user expectations and business goals.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>AI-Driven Data Insights</h2>
            <p>
              Unlock the full potential of your data with our AI-powered
              insights. We utilize machine learning algorithms to analyze vast
              datasets, uncover patterns, and provide actionable intelligence
              that drives decision-making and accelerates business growth.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Custom AI & ML Solutions</h2>
            <p>
              We create tailored AI and machine learning models that solve
              complex problems. From natural language processing to image
              recognition, our solutions are designed to meet the unique
              challenges faced by enterprises looking to innovate.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Enterprise-Level AI Systems</h2>
            <p>
              Our enterprise-grade AI systems offer scalability and flexibility.
              We develop models that handle large datasets, ensuring robust
              performance across industries, while integrating with your
              existing infrastructure for seamless AI-driven operations.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIservmainsec;
