import React, { useState, useEffect, useRef } from "react";
import "./industry.css";
import './servicepages.css';
import '../components/servmainsec/Servmainsec.css';
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import ProjectinMind from "../components/projectinmind/ProjectinMind";

const RealEstate = () => {
  const [activeSection, setActiveSection] = useState("Industry Overview");

  // Refs to track each section
  const overviewRef = useRef(null);
  const technologyRef = useRef(null);
  const propertyManagementRef = useRef(null);
  const virtualToursRef = useRef(null);

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: overviewRef, id: "Industry Overview" },
      { ref: technologyRef, id: "Role of Technology" },
      { ref: propertyManagementRef, id: "Property Management Solutions" },
      { ref: virtualToursRef, id: "Virtual Tours and 3D Visualization" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="industry-main">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>Real Estate
        </h6>
        <h1>Real Estate</h1>
        <p>
          The real estate industry is increasingly leveraging technology to streamline processes, enhance customer experiences, and improve overall efficiency. Information technology (IT) solutions play a vital role in transforming the way properties are bought, sold, and managed.
        </p>
      </div>

      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Industry Overview",
              "Role of Technology",
              "Property Management Solutions",
              "Virtual Tours and 3D Visualization",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={overviewRef} className="section-content">
            <h2>Industry Overview</h2>
            <p>
              The real estate sector is evolving rapidly, with technology playing a key role in enhancing operational efficiency and client engagement. From property searches to transactions, IT is transforming the entire real estate landscape.
            </p>
          </div>

          <div ref={technologyRef} className="section-content">
            <h2>Role of Technology</h2>
            <p>
              Technology in real estate encompasses a range of solutions that simplify processes, such as online listings, customer relationship management (CRM) systems, and mobile applications, making property transactions more accessible and user-friendly.
            </p>
          </div>

          <div ref={propertyManagementRef} className="section-content">
            <h2>Property Management Solutions</h2>
            <p>
              IT solutions for property management help streamline operations, from tenant screening and lease management to maintenance requests and payment processing, providing a seamless experience for both property owners and tenants.
            </p>
          </div>

          <div ref={virtualToursRef} className="section-content">
            <h2>Virtual Tours and 3D Visualization</h2>
            <p>
              Virtual tours and 3D visualization technology allow potential buyers to explore properties remotely, providing an immersive experience that enhances decision-making and engagement, particularly in a competitive market.
            </p>
          </div>
        </div>
      </div>

      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our real estate technology solutions are designed to empower agents and clients alike, improving the way properties are marketed and managed in the digital age.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Transforming Real Estate Through Technology</h1>
            <p>
              We specialize in developing innovative real estate solutions that leverage technology to improve the efficiency of property transactions and management, ensuring a superior experience for all stakeholders.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>Real Estate CRM Systems</h2>
            <p>
              Our custom CRM solutions help real estate professionals manage client relationships, track leads, and enhance communication, ultimately boosting sales and customer satisfaction.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Online Listing Platforms</h2>
            <p>
              We create user-friendly online listing platforms that allow sellers to showcase properties effectively while providing buyers with easy access to search and filter listings based on their preferences.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Marketing Automation Tools</h2>
            <p>
              Our marketing automation solutions enable real estate businesses to streamline their marketing efforts, utilizing data-driven insights to target potential buyers and increase property visibility.
            </p>
          </div>
        </div>
      </div>
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default RealEstate;
