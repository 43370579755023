import React from "react";
import "./Servmainsec.css";

const Uiservmainsec = () => {
  return (
    <>
      <div className="servicemainsectionmain">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>UI & UX DESIGN SERVICES
        </h6>
        <h1>UI & UX Design</h1>
        <p>
          Looking to elevate your digital products with exceptional UI & UX
          design? Our team specializes in creating intuitive and engaging
          interfaces that enhance user satisfaction and drive business growth.
        </p>
      </div>
      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our UI & UX design services focus on delivering user-centered
              designs that combine functionality and aesthetics to create
              seamless digital experiences.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Crafting User Experiences that Captivate</h1>
            <p>
              We specialize in developing user interfaces that are not only
              visually appealing but also enhance usability. Our design process
              is centered around understanding user needs and behaviors to
              deliver optimal experiences.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>User Research and Analysis</h2>
            <p>
              We conduct thorough user research to understand your target
              audience, identifying their preferences and pain points. This
              insights-driven approach informs our design decisions, ensuring a
              user-centric outcome.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Prototyping and Testing</h2>
            <p>
              Our team creates interactive prototypes that allow for user
              testing and feedback. This iterative process helps refine designs,
              ensuring that the final product meets user expectations and
              business goals.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Responsive and Accessible Designs</h2>
            <p>
              We prioritize responsive design, ensuring your product looks and
              functions beautifully across all devices. Additionally, we adhere
              to accessibility standards, making sure everyone can use and enjoy
              your digital products.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Uiservmainsec;
