import React from "react";
import "./Servmainsec.css";

const Socialmservmainse = () => {
  return (
    <>
      <div className="servicemainsectionmain">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>SOCIAL MEDIA MARKETING AND MANAGEMENT SERVICES
        </h6>
        <h1>Social Media Marketing and Management</h1>
        <p>
          Looking to enhance your online presence? Our social media marketing and management services are designed to boost your brand visibility and engagement across various platforms.
        </p>
      </div>
      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              We create tailored social media strategies that resonate with your target audience, helping you achieve your marketing goals effectively.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Boosting Brand Awareness</h1>
            <p>
              Our expert team develops compelling content that drives user interaction and builds a loyal community around your brand.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>Content Creation</h2>
            <p>
              We specialize in crafting engaging and shareable content that aligns with your brand's voice and appeals to your audience.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Community Management</h2>
            <p>
              Our team monitors and engages with your audience, ensuring timely responses and fostering positive interactions on your social platforms.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Analytics and Reporting</h2>
            <p>
              We provide detailed analytics and reports, allowing you to track your social media performance and adjust strategies as needed for optimal results.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Socialmservmainse;
