import React from "react";
import "./Servmainsec.css";

const Videoeditservmainsec = () => {
  return (
    <>
      <div className="servicemainsectionmain">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>VIDEO EDITING SERVICES
        </h6>
        <h1>Video Editing</h1>
        <p>
          Looking to transform your raw footage into captivating stories? Our expert video editing services enhance your visuals and sound to create compelling content that engages your audience.
        </p>
      </div>
      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our video editing services focus on bringing your vision to life, combining creativity and technical expertise to produce high-quality videos tailored to your needs.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Crafting Visual Stories That Captivate</h1>
            <p>
              We specialize in editing videos that not only look good but also tell a compelling story. Our process involves understanding your message and audience to create impactful video content.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>Creative Editing Techniques</h2>
            <p>
              Our team employs advanced editing techniques to enhance your footage, including color correction, audio enhancement, and special effects, ensuring your video stands out.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Customizable Packages</h2>
            <p>
              We offer a range of customizable editing packages to fit different project needs and budgets. Whether it’s a short promotional video or a full-length film, we have the right solution for you.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Timely Delivery</h2>
            <p>
              We understand the importance of deadlines. Our efficient workflow ensures that your edited videos are delivered on time, without compromising on quality.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Videoeditservmainsec;
