import React from "react";
import "./experiencecount.css";
import Video from "../media/homepagevideo.mp4";
import Aws from "../media/aws.webp";
import Cloudera from "../media/Cloudera global.systemsltd..webp";
import Salesforce from "../media/Salesforce globla.systemsltd (1).webp";
import Temenos from "../media/temenos (1).webp";
import Sap from "../media/sap.webp";

const Experiencecount = () => {
  return (
    <>
      <div class="experience">
        <div class="experience-video fcc">
          <video autoPlay muted loop src={Video}></video>
        </div>
        <div class="experience-left-right">
          <div class="experience-left">
            <h1>Translating technology into a positive impact</h1>
            <p>
              Our approach allows us to deliver exceptional experiences that
              drive growth and success for all stakeholders. Let’s rise to new
              heights with the power of digital transformation.
            </p>
          </div>
          <div class="experience-right">
            <div class="sec2-right-top">
              <div class="sec2-right-top1">
                <h1>17+</h1>
                <h4>Years of continual excellence</h4>
              </div>
              <div class="sec2-right-top2">
                <h1>5000+</h1>
                <h4>Change makers driving revolution</h4>
              </div>
            </div>
            <div class="sec2-right-bottom">
              <div class="sec2-right-bottom1">
                <h1>16+</h1>
                <h4>Countries with our presence</h4>
              </div>
              <div class="sec2-right-bottom2">
                <h1>100+</h1>
                <h4>Active clients across the globe</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="experience-bottom">
          <h2>
            We rethink business growth for you through our collective experience
            with strategic partner ecosystem.
          </h2>
          <div class="sec2-bottom-imgs">
            <img src={Aws} alt="" />
            <img src={Cloudera} alt="" />
            <img src={Sap} alt="" />
            <img src={Salesforce} alt="" />
            <img src={Temenos} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Experiencecount;
