import React from "react";
import "./Servmainsec.css";
// import hh from "../media/about-banner.jpg";

const Servmainsec = () => {
  return (
    <>
      <div className="servicemainsectionmain">
        {/* <img src={hh} alt="" /> */}
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>WEB DEVELOPMENT
        </h6>
        <h1>Web Development</h1>
        <p>
          Looking to develop a scalable, responsive, and future-proof web
          solution? Our web development services offer custom-built websites 
          tailored to your business goals, ensuring high performance, security,
          and a seamless user experience.
        </p>
      </div>
      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              We build optimized, SEO-friendly websites designed to engage your
              audience and drive growth, ensuring your online presence remains
              competitive and user-centric.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Optimizing the Digital Experience</h1>
            <p>
              Our web development process focuses on enhancing user experience
              at every touchpoint, ensuring intuitive navigation, fast loading
              times, and a mobile-responsive design that drives user engagement
              and satisfaction.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>Conversion-Driven UX Design</h2>
            <p>
              Our websites are crafted with Conversion Rate Optimization (CRO)
              principles, blending user-centered design with strategic layouts
              that turn visitors into customers, maximizing your business
              outcomes.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Mobile-Optimized Web Development</h2>
            <p>
              Our expertise in responsive web design ensures your site works
              flawlessly across all devices, providing a consistent and engaging
              experience for users on desktops, tablets, and smartphones.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Scalable Web Solutions</h2>
            <p>
              We specialize in creating scalable and secure web platforms, 
              ensuring that your website can grow alongside your business needs 
              while maintaining top performance and reliability.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Servmainsec;
