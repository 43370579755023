import React from "react";
import "./Servmainsec.css";

const Adsenseservmainsec = () => {
  return (
    <>
      <div className="servicemainsectionmain">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>GOOGLE ADSENSE SERVICES
        </h6>
        <h1>Google AdSense Services</h1>
        <p>
          Want to monetize your website effectively? Our Google AdSense services are designed to maximize your ad revenue while ensuring a seamless user experience.
        </p>
      </div>
      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              We help you implement Google AdSense on your website with optimized ad placements that enhance visibility and click-through rates.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Maximizing Ad Revenue</h1>
            <p>
              Our expert team analyzes your website traffic and content to strategize the best ad formats and placements for maximum profitability.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>Ad Optimization</h2>
            <p>
              We continuously monitor and optimize your ad performance, ensuring that you get the best results and return on investment.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Compliance and Support</h2>
            <p>
              Our team ensures your website complies with Google AdSense policies, providing guidance and support to avoid common pitfalls.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Performance Analytics</h2>
            <p>
              We provide comprehensive analytics reports to help you understand your ad performance and make informed decisions for future strategies.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Adsenseservmainsec;
