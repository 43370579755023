import React, { useState, useEffect, useRef } from "react";
import "./servicepages.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Servmainsec from "../components/servmainsec/Webservmainsec";
import Awards from "../components/awards/Awards";
import ProjectinMind from "../components/projectinmind/ProjectinMind";
import AIservmainsec from "../components/servmainsec/AIservmainsec";
import Uiservmainsec from "../components/servmainsec/Uiservmainsec";

const Ui_Ux = () => {
  const [activeSection, setActiveSection] = useState("UI & UX Design Process");

  // Refs to track each section
  const processRef = useRef(null);
  const userResearchRef = useRef(null);
  const prototypingRef = useRef(null);
  const responsiveDesignRef = useRef(null); // New heading related to responsive design

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: processRef, id: "UI & UX Design Process" },
      { ref: userResearchRef, id: "User Research and Analysis" },
      { ref: prototypingRef, id: "Prototyping and Testing" },
      { ref: responsiveDesignRef, id: "Responsive and Accessible Designs" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Uiservmainsec />

      <h2 className="Serv-head">UI & UX Design Services</h2>
      <p className="serv-desc">
        We provide innovative UI & UX design services that enhance user
        satisfaction and drive engagement, focusing on user-centered design
        principles.
      </p>
      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "UI & UX Design Process",
              "User Research and Analysis",
              "Prototyping and Testing",
              "Responsive and Accessible Designs",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={processRef} className="section-content">
            <h2>UI & UX Design Process</h2>
            <p>
              Our UI & UX design process is structured to ensure a user-centered
              approach, involving phases from research to implementation. We use
              Agile methodologies to deliver designs that align with user needs
              and business objectives.
            </p>
          </div>

          <div ref={userResearchRef} className="section-content">
            <h2>User Research and Analysis</h2>
            <p>
              We conduct in-depth user research to identify user preferences and
              challenges. This data-driven approach informs our design
              strategies, enabling us to create interfaces that resonate with
              users.
            </p>
          </div>

          <div ref={prototypingRef} className="section-content">
            <h2>Prototyping and Testing</h2>
            <p>
              Our team develops interactive prototypes that facilitate user
              testing. This iterative feedback loop ensures our designs meet
              user expectations and are refined for optimal usability.
            </p>
          </div>

          <div ref={responsiveDesignRef} className="section-content">
            <h2>Responsive and Accessible Designs</h2>
            <p>
              We prioritize responsive design practices, ensuring that your
              product performs seamlessly across various devices. Our designs
              also comply with accessibility standards to provide inclusive user
              experiences.
            </p>
          </div>
        </div>
      </div>

      <Awards />
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default Ui_Ux;
