import React from "react";
import "./testimonials.css";
import jkn from "../media/about-banner.jpg";
import client1 from "../media/client1.webp";
import client2 from "../media/client3.webp";
import client3 from "../media/client2.jpeg";
import client4 from "../media/client4.webp";

const Testimonials = () => {
  return (
    <>
      <div class="testimonial">
        <div class="testimonial-left">
          <h3>Testimonials</h3>
          <h1>What Our Clients Say About Us</h1>
          <p>
            We are proud to help our clients bring their visions to life across
            a range of digital solutions. From website design to app development
            and everything in between, we are committed to delivering
            exceptional service. Here’s what our clients have to say:
          </p>
        </div>
        <div class="testimonial-right">
          <div class="testim">
            <h1>
              "Collaborating with US Tech Innovations has revolutionized our
              real estate operations. Their team crafted a tailored platform
              that optimized our workflows, resulting in a 30% boost in
              efficiency. The professionalism and skill they contributed to the
              project surpassed our expectations. I strongly endorse their
              services to any agency aiming to enhance their business."
            </h1>
            <div class="testim-img-name">
              <img src={client1} alt="" />
              <p>Michael Scott – Real Estate Agent</p>
            </div>
          </div>
          <div class="testim">
            <h1>
              "US Tech Innovations offered us remarkable digital marketing
              services that considerably improved our online visibility. Their
              strategic methods for SEO and social media outreach resulted in a
              50% rise in website traffic in just three months. The team's
              careful attention to detail and commitment to our success made
              them an invaluable collaborator."
            </h1>
            <div class="testim-img-name">
              <img src={client4} alt="" />
              <p>Jessica Morgan – Physical Therapist</p>
            </div>
          </div>
          <div class="testim">
            <h1>
              "The branding and design services provided by US Tech Innovations
              were outstanding. They truly understood the essence of our brand
              and transformed it into a captivating visual identity that
              connects with our customers. The collaborative experience was
              effortless, and their creativity and professionalism were evident
              at every stage of the process."
            </h1>
            <div class="testim-img-name">
              <img src={client3} alt="" />
              <p>Mark Johnson – Online Store Owner</p>
            </div>
          </div>
          <div class="testim">
            <h1>
              "US Tech Innovations has played a crucial role in our software
              development initiatives. Their capability to provide top-notch
              solutions promptly and within budget is impressive. The team is
              not only technically skilled but also comprehends our business
              requirements, establishing them as a reliable partner in our
              growth journey."
            </h1>
            <div class="testim-img-name">
              <img src={client2} alt="" />
              <p>Samantha Johnson – Financial Advisor</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
