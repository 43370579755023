import React from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import "./about.css";
import aboutbanner from "../components/media/about-banner.jpg";

const About = () => {
  return (
    <div>
      <Navbar />
      <div class="ab-section1">
        <img src={aboutbanner} alt="" />
      </div>
      <div class="ab-section2">
        <div class="about-disc">
          <h2>About US Tech Innovations</h2>
          <p>
            Founded with a vision to transform the tech landscape, US Tech
            Innovations is dedicated to providing cutting-edge software
            solutions that empower businesses to thrive in a digital-first
            world.
          </p>
          <p>
            We are a collective of passionate professionals who believe in the
            transformative power of technology and its ability to drive success
            through collaboration and innovation.
          </p>
          <p>
            By partnering with organizations that share our commitment to
            excellence, sustainability, and impactful results, we create
            software solutions that truly resonate with our clients and their
            target audiences.
          </p>
          <p>
            Our strategic approach ensures exceptional outcomes and fosters
            enduring relationships with our clients, helping them achieve their
            goals in an ever-evolving market.
          </p>
          <p>
            With years of industry experience, we take pride in being a trusted
            partner for businesses of all sizes, delivering award-winning
            solutions that enhance their operational efficiency and market
            presence.
          </p>
        </div>
      </div>
      <div class="ab-section3">
        <div class="ab-section3-left">
          <h3>Design</h3>
          <p>
            At US Tech Innovations, we excel in comprehensive design services
            tailored to your brand’s needs. Whether you require graphic design,
            branding, user interfaces, or marketing materials, our creative team
            is here to elevate your identity.
          </p>
          <h5>— Graphic Design</h5>
          <h5>— Branding Solutions</h5>
          <h5>— User Interface Design</h5>
          <h5>— Marketing Collateral</h5>
          <h5>— Packaging Design</h5>
          <h5>— Digital Advertisements</h5>
          <h5>— Social Media Graphics</h5>
          <h5>— Presentation Design</h5>
          <h5>— Merchandise Design</h5>
          <h5>— Infographic Design</h5>
        </div>
        <div class="ab-section3-right">
          <h3>Development</h3>
          <p>
            In today's digital age, a robust online presence is vital for any
            business. Our development team guides you through the entire
            process, from initial site mapping and wireframing to final design
            and user testing. We specialize in creating responsive websites that
            perform seamlessly across all devices.
          </p>
          <h5>— Website Design and Development</h5>
          <h5>— E-Commerce Solutions</h5>
          <h5>— Mobile App Design and Development</h5>
          <h5>— UI & UX Design</h5>
          <h5>— WordPress Development</h5>
          <h5>— Front-End Development</h5>
          <h5>— Back-End Development</h5>
          <h5>— Cross-Device Responsiveness</h5>
          <h5>— API Development and Integration</h5>
          <h5>— Shopify Solutions</h5>
        </div>
      </div>

      <div class="ab-section4">
        <h1>FEES .</h1>
        <p>
          At US Tech Innovations, we offer flexible pricing structures to
          accommodate your business needs. Choose from a variety of options:
        </p>
        <h6>Hourly Rates:</h6>
        <p>Our professional services are billed at competitive hourly rates.</p>
        <h6>Fixed or Capped Price:</h6>
        <p>We provide an agreed price or cap for specific projects or tasks.</p>
        <h6>Retainer:</h6>
        <p>
          A consistent monthly fee for ongoing services over an agreed period.
        </p>
        <h6>Equity:</h6>
        <p>
          In select cases, we collaborate with start-ups and early-stage
          companies on a mixed fee/equity arrangement.
        </p>
        <p>
          Contact us today to schedule a no-obligation meeting to discuss your
          project and explore the various pricing options available.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default About;
