import React, { useState, useEffect, useRef } from "react";
import "./servicepages.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Faq from "../components/faqs/Faq";
import Appservmainsec from "../components/servmainsec/Appservmainsec";
import Awards from "../components/awards/Awards";
import ProjectinMind from "../components/projectinmind/ProjectinMind";

const Services = () => {
  const [activeSection, setActiveSection] = useState("Development Process");

  // Refs to track each section
  const processRef = useRef(null);
  const responsiveRef = useRef(null);
  const maintenanceRef = useRef(null);
  const securityRef = useRef(null); // New heading related to web security

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: processRef, id: "Development Process" },
      { ref: responsiveRef, id: "Responsive Design" },
      { ref: maintenanceRef, id: "Website Maintenance" },
      { ref: securityRef, id: "Web Security Best Practices" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Appservmainsec />

      <h2 className="Serv-head">App Development Services</h2>
      <p className="serv-desc">
        We specialize in app development services, ensuring the highest quality
        from initial concept to launch. Our team creates mobile applications
        that are scalable, user-friendly, and tailored to meet your business
        goals.
      </p>
      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Development Process",
              "Responsive Design",
              "App Maintenance",
              "App Security Best Practices",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={processRef} className="section-content">
            <h2>Development Process</h2>
            <p>
              Our app development process follows industry-standard practices to
              ensure every step from design to deployment is seamless. We use
              Agile methods to deliver top-performing apps tailored to your
              business needs.
            </p>
          </div>

          <div ref={responsiveRef} className="section-content">
            <h2>Responsive Design</h2>
            <p>
              We ensure that the apps we develop are responsive across all
              devices, providing a seamless experience whether users are on a
              phone, tablet, or desktop.
            </p>
          </div>

          <div ref={maintenanceRef} className="section-content">
            <h2>App Maintenance</h2>
            <p>
              Our comprehensive app maintenance services include regular
              updates, bug fixes, and performance optimization to keep your
              application running smoothly.
            </p>
          </div>

          <div ref={securityRef} className="section-content">
            <h2>App Security Best Practices</h2>
            <p>
              We prioritize security in our development process, implementing
              robust measures to protect your app from potential threats,
              ensuring user data is safe and secure.
            </p>
          </div>
        </div>
      </div>

      <Awards />
      <Faq />
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default Services;
