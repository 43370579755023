import React, { useState, useEffect, useRef } from "react";
import "./industry.css";
import './servicepages.css';
import '../components/servmainsec/Servmainsec.css';
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import ProjectinMind from "../components/projectinmind/ProjectinMind";

const WellnessFitness = () => {
  const [activeSection, setActiveSection] = useState("Industry Overview");

  // Refs to track each section
  const overviewRef = useRef(null);
  const technologyRef = useRef(null);
  const fitnessTrackingRef = useRef(null);
  const virtualTrainingRef = useRef(null);

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: overviewRef, id: "Industry Overview" },
      { ref: technologyRef, id: "Role of Technology" },
      { ref: fitnessTrackingRef, id: "Fitness Tracking Solutions" },
      { ref: virtualTrainingRef, id: "Virtual Training and Classes" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="industry-main">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>Wellness & Fitness
        </h6>
        <h1>Wellness & Fitness</h1>
        <p>
          The wellness and fitness industry is rapidly evolving, with technology playing a pivotal role in enhancing user experiences, improving accessibility, and fostering healthier lifestyles. Information technology (IT) solutions are transforming how individuals engage with their fitness and wellness journeys.
        </p>
      </div>

      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Industry Overview",
              "Role of Technology",
              "Fitness Tracking Solutions",
              "Virtual Training and Classes",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={overviewRef} className="section-content">
            <h2>Industry Overview</h2>
            <p>
              The wellness and fitness industry is embracing technology to create personalized and engaging experiences. From fitness apps to health monitoring devices, IT solutions are empowering individuals to take charge of their wellness journeys.
            </p>
          </div>

          <div ref={technologyRef} className="section-content">
            <h2>Role of Technology</h2>
            <p>
              Technology plays a crucial role in enhancing the wellness experience, offering tools that help individuals track their fitness progress, manage nutrition, and access expert guidance from fitness professionals, all from their mobile devices.
            </p>
          </div>

          <div ref={fitnessTrackingRef} className="section-content">
            <h2>Fitness Tracking Solutions</h2>
            <p>
              Our fitness tracking solutions integrate wearable technology and mobile applications to help users monitor their workouts, heart rate, and other health metrics. This data-driven approach encourages healthier habits and accountability.
            </p>
          </div>

          <div ref={virtualTrainingRef} className="section-content">
            <h2>Virtual Training and Classes</h2>
            <p>
              Virtual training platforms offer users the flexibility to participate in classes and workouts from the comfort of their homes. Live streaming sessions and on-demand videos make fitness accessible and convenient for everyone.
            </p>
          </div>
        </div>
      </div>

      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our wellness technology solutions are designed to motivate and engage users, providing a holistic approach to fitness and wellbeing in a digital landscape.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Empowering Health and Fitness Through Technology</h1>
            <p>
              We specialize in creating innovative solutions that integrate technology into the wellness experience, helping individuals achieve their fitness goals and maintain a balanced lifestyle.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>Personalized Wellness Apps</h2>
            <p>
              Our personalized wellness apps offer tailored workout plans, nutrition tracking, and wellness tips, ensuring users receive the guidance they need to succeed in their fitness journeys.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Health Monitoring Solutions</h2>
            <p>
              We provide comprehensive health monitoring solutions that sync with wearable devices to track vital signs and fitness metrics, empowering users to stay informed about their health status.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Community and Support Platforms</h2>
            <p>
              Our platforms connect users with fitness communities, offering support, motivation, and accountability through social features and group challenges that enhance the fitness experience.
            </p>
          </div>
        </div>
      </div>
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default WellnessFitness;
