import React, { useState, useEffect, useRef } from "react";
import './servicepages.css'
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Servmainsec from "../components/servmainsec/Webservmainsec";
import Awards from "../components/awards/Awards";
import ProjectinMind from "../components/projectinmind/ProjectinMind";
import Adsenseservmainsec from "../components/servmainsec/Adsenseservmainsec";

const Adsense = () => {
  const [activeSection, setActiveSection] = useState("Ad Optimization");

  // Refs to track each section
  const adOptimizationRef = useRef(null);
  const complianceSupportRef = useRef(null);
  const performanceAnalyticsRef = useRef(null);

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: adOptimizationRef, id: "Ad Optimization" },
      { ref: complianceSupportRef, id: "Compliance and Support" },
      { ref: performanceAnalyticsRef, id: "Performance Analytics" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Adsenseservmainsec />

      <h2 className="Serv-head">Google AdSense Services</h2>
      <p className="serv-desc">
        Our Google AdSense services are designed to help you monetize your
        website effectively, maximizing ad revenue while maintaining user
        experience.
      </p>
      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {[
              "Ad Optimization",
              "Compliance and Support",
              "Performance Analytics",
            ].map((service, index) => (
              <div
                key={index}
                className={`left-heading ${
                  activeSection === service ? "active" : ""
                }`}
              >
                {service}
              </div>
            ))}
          </div>
        </div>
        <div className="right-section">
          <div ref={adOptimizationRef} className="section-content">
            <h2>Ad Optimization</h2>
            <p>
              We implement the best practices in ad placement and format
              selection to ensure your ads are seen by the right audience and
              yield maximum revenue.
            </p>
          </div>

          <div ref={complianceSupportRef} className="section-content">
            <h2>Compliance and Support</h2>
            <p>
              Our experts provide ongoing support to ensure your site complies
              with Google AdSense guidelines, helping you navigate any
              challenges that arise.
            </p>
          </div>

          <div ref={performanceAnalyticsRef} className="section-content">
            <h2>Performance Analytics</h2>
            <p>
              We offer in-depth analytics that track your ad performance,
              providing insights that inform your future strategies and maximize
              revenue.
            </p>
          </div>
        </div>
      </div>

      <Awards />
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default Adsense;
