import React, { useState, useEffect, useRef } from "react";
import "./servicepages.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Servmainsec from "../components/servmainsec/Webservmainsec";
import Awards from "../components/awards/Awards";
import ProjectinMind from "../components/projectinmind/ProjectinMind";
import AIservmainsec from "../components/servmainsec/AIservmainsec";
import SEOservmainsec from "../components/servmainsec/Seoservmainsec";

const Services = () => {
  const [activeSection, setActiveSection] = useState("On-Page SEO");

  // Refs to track each section
  const onPageSEORef = useRef(null);
  const offPageSEORef = useRef(null);
  const performanceAnalyticsRef = useRef(null);

  // Function to handle scrolling and update the active section
  const handleScroll = () => {
    const sections = [
      { ref: onPageSEORef, id: "On-Page SEO" },
      { ref: offPageSEORef, id: "Off-Page SEO" },
      { ref: performanceAnalyticsRef, id: "Performance Analytics" },
    ];

    sections.forEach((section) => {
      const top = section.ref.current.getBoundingClientRect().top;
      if (top >= 0 && top <= 200) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <SEOservmainsec />

      <h2 className="Serv-head">SEO Services</h2>
      <p className="serv-desc">
        Our SEO services are designed to improve your search engine rankings and
        increase organic traffic to your website.
      </p>
      <div className="main-section">
        <div className="left-section">
          <div className="sticky-container">
            {["On-Page SEO", "Off-Page SEO", "Performance Analytics"].map(
              (service, index) => (
                <div
                  key={index}
                  className={`left-heading ${
                    activeSection === service ? "active" : ""
                  }`}
                >
                  {service}
                </div>
              )
            )}
          </div>
        </div>
        <div className="right-section">
          <div ref={onPageSEORef} className="section-content">
            <h2>On-Page SEO</h2>
            <p>
              We optimize your website's content, including meta tags, titles,
              and images, to enhance visibility and rankings on search engines.
            </p>
          </div>

          <div ref={offPageSEORef} className="section-content">
            <h2>Off-Page SEO</h2>
            <p>
              Our team helps build authoritative backlinks from reputable
              websites to boost your domain authority and improve your search
              rankings.
            </p>
          </div>

          <div ref={performanceAnalyticsRef} className="section-content">
            <h2>Performance Analytics</h2>
            <p>
              We provide detailed analytics reports to track your SEO
              performance and refine your strategy for continued success.
            </p>
          </div>
        </div>
      </div>

      <Awards />
      <ProjectinMind />
      <Footer />
    </>
  );
};

export default Services;
