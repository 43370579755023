import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./projectinmind.css";
import projindmind from "../media/40361c9f-f60d-4c7a-bf82-095ae36bcb44.webp";
import { gsap } from "gsap";

const ProjectinMind = () => {
  const sectionRef = useRef(null);
  const cursorRef = useRef(null);
  const scrollTimeoutRef = useRef(null); // To hold the scroll timeout reference

  useEffect(() => {
    const section = sectionRef.current;
    const cursor = cursorRef.current;

    const handleMouseMove = (event) => {
      gsap.to(cursor, {
        x: event.clientX,
        y: event.clientY,
        opacity: 1, // Restore opacity on mouse movement
      });
    };

    const handleMouseEnter = () => {
      gsap.to(cursor, {
        scale: 1,
        opacity: 1,
      });
    };

    const handleMouseLeave = () => {
      gsap.to(cursor, {
        scale: 0,
        opacity: 0,
      });
    };

    const handleScroll = () => {
      // Hide cursor on scroll
      gsap.to(cursor, { opacity: 0 });
      
      // Clear previous timeout and set new one to handle debounce effect
      clearTimeout(scrollTimeoutRef.current);
      scrollTimeoutRef.current = setTimeout(() => {
        gsap.to(cursor, { opacity: 1 }); // Restore opacity after scrolling stops
      }, 200);
    };

    if (section) {
      section.addEventListener("mousemove", handleMouseMove);
      section.addEventListener("mouseenter", handleMouseEnter);
      section.addEventListener("mouseleave", handleMouseLeave);
      window.addEventListener("scroll", handleScroll); // Attach scroll listener to window
    }

    return () => {
      if (section) {
        section.removeEventListener("mousemove", handleMouseMove);
        section.removeEventListener("mouseenter", handleMouseEnter);
        section.removeEventListener("mouseleave", handleMouseLeave);
        window.removeEventListener("scroll", handleScroll); // Clean up scroll listener
        clearTimeout(scrollTimeoutRef.current); // Clean up timeout
      }
    };
  }, []);

  return (
    <>
      <div className="projinmind-main" ref={sectionRef}>
        <Link to="/Contact" className="contact-link">
          <div className="cursor" ref={cursorRef}>
            <h4>LET'S CONNECT→</h4>
          </div>
        </Link>

        <Link to='/Contact'>
          <div className="mobile-cursor">
            <h4>LET'S CONNECT→</h4>
          </div>
        </Link>

        <img src={projindmind} alt="" />
        <div className="projinminddesc">
          <h1>Empowering Your Vision</h1>
          <h1>With Innovation</h1>
          <p>
            Let us help you turn your ideas into reality with cutting-edge
            software solutions designed to meet your unique business needs.
          </p>
        </div>
      </div>
    </>
  );
};

export default ProjectinMind;
