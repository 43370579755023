import React, { useState, useEffect } from "react";
import "./contact.css";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import contactline from "../components/media/contactline.svg";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    explain: "",
  });
  const [status, setStatus] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Check if all fields are filled
    const allFieldsFilled = Object.values(formData).every((field) => field.trim() !== "");
    setIsFormValid(allFieldsFilled);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://ustechinnovations.agency/backend/contact.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      });

      if (response.ok) {
        setStatus({
          message: "Thank you for your submission! We’ll be in touch with you soon.",
          success: true,
        });
        setFormData({ name: "", email: "", phone: "", explain: "" });
      } else {
        setStatus({
          message: "Something went wrong. Please try again.",
          success: false,
        });
      }
    } catch (error) {
      setStatus({
        message: "Network error. Please try again.",
        success: false,
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="cont-section1">
        <div className="cont-section1-left">
          <h1>Let's Create Something</h1>
          <h1>Epic Together.</h1>
          <form id="contactForm" onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <label htmlFor="name">Your Name *</label>
            </div>
            <div className="input-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label htmlFor="email">Email *</label>
            </div>
            <div className="input-group">
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <label htmlFor="phone">Phone *</label>
            </div>
            <textarea
              name="explain"
              value={formData.explain}
              onChange={handleChange}
              placeholder="Explain Project Idea.."
              required
            ></textarea>

            <button
              id="submitButton"
              type="submit"
              disabled={!isFormValid}
              className={isFormValid ? "enabled" : ""}
            >
              SUBMIT
            </button>
          </form>
          {status && (
            <p className={status.success ? "success-message" : "error-message"}>
              {status.message}
            </p>
          )}
        </div>
        <div className="cont-section1-right">
          <h2>Our Mission</h2>
          <p>
            “At US Tech Innovations, we strive to bring innovative solutions
            that empower businesses to excel in the digital age. Our commitment
            to excellence drives us to deliver top-notch tech services tailored
            to your needs. Let’s work together to turn your vision into reality
            and take your business to new heights!”
          </p>
          <h5>Email:</h5>
          <p>contact@ustechinnovations.agency</p>
          <img src={contactline} alt="" />
          <h4>Number:</h4>
          <p>+1 (386)319-3387</p>
          <img src={contactline} alt="" />
          <h4>Location:</h4>
          <p>
            606 Redwood Boulevard, Floor 7, Los Angeles, CA 90017, United
            States.
          </p>
          <p>
            101 Wellington Street, 6th Floor, Office 220, Ottawa, ON K1A 0A4,
            Canada.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
