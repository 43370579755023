import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import logo from "../media/usatechinnovations-high-resolution-logo.png";
import logo1 from "../media/usatechinnovations-high-resolution-logo-removebg-preview.webp";
import navleft from "../media/navleftimage.webp";
import navindustryleft from "../media/navindustryleft.webp";
import { useState } from "react";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServiceOpen, setIsServiceOpen] = useState(false);
  const [isIndustryOpen, setIsIndustryOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleServiceDropdown = () => {
    setIsServiceOpen(!isServiceOpen);
  };

  const toggleIndustryDropdown = () => {
    setIsIndustryOpen(!isIndustryOpen);
  };

  return (
    <div className="navb">
      <nav class="fcsb">
        <div className="nav-media">
          <Link to="/">
            <div className="logo-name">
              <img id="nav-logo" src={logo1} alt="" />
              <h1>US TECH INNOVATIONS</h1>
            </div>
          </Link>
          <div className="nav_burger" onClick={toggleMenu}>
            <div className="c-nav__burger__line u-1"></div>
            <div className="c-nav__burger__line u-2"></div>
          </div>

          {/* Mobile Navigation */}
          <div className={`mob-nav ${isMenuOpen ? "open" : ""}`}>
            <ul className="mob-nav-links">
              <li id="home">
                <Link to="/">HOME</Link>
              </li>
              <li onClick={toggleServiceDropdown}>
                SERVICES<span className="servnavarrow">▾</span>
                <ul className={`dropdown ${isServiceOpen ? "open" : ""}`}>
                  <div className="dd">
                    <li>
                      <Link to="/Webdevelopment">Web Development →</Link>
                    </li>
                    <li>
                      <Link to="/Appdevelopment">App Development →</Link>
                    </li>
                    <li>
                      <Link to="/AI_ML">AI & ML →</Link>
                    </li>
                    <li>
                      <Link to="/Wordpress">Wordpress Development →</Link>
                    </li>
                    <li>
                      <Link to="/UI_UX">
                        UI & UX <span>→</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/VideoEditing">
                        Video Editing <span>→</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/LogoDesign">
                        Logo Designs <span>→</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/SocialMediaMarketingandManagement">
                        Social Media Marketing <span>→</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/SEO">
                        SEO <span>→</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/Adsense">
                        Google Adsense <span>→</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/DigitalMarketing">
                        Digital Marketing <span>→</span>
                      </Link>
                    </li>
                  </div>
                </ul>
              </li>
              <li onClick={toggleIndustryDropdown}>
                INDUSTRIES<span className="servnavarrow">▾</span>
                <ul className={`dropdown ${isIndustryOpen ? "open" : ""}`}>
                  <div className="dd">
                    {/* Add industries here */}
                    <li>
                      <Link to="/Real_Estate">Real Estate →</Link>
                    </li>
                    <li>
                      <Link to="/HealthCare">HealthCare →</Link>
                    </li>
                    <li>
                      <Link to="/E_Commerce">E-Commerce →</Link>
                    </li>
                    <li>
                      <Link to="/Travel">Travel →</Link>
                    </li>
                    <li>
                      <Link to="/Education">Education →</Link>
                    </li>
                    <li>
                      <Link to="/Wellness_Fitness">Wellness and Fitness →</Link>
                    </li>
                  </div>
                </ul>
              </li>
              <li>
                <Link to="/About">ABOUT</Link>
              </li>
              <li>
                <Link id="nav-talk" to="/Contact">
                  LET'S TALK <span className="navtalkarrow">→</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <ul id="navmainul">
          <li>
            <Link to="/">HOME</Link>
          </li>
          <li id="services">
            SERVICES<span className="servnavarrow">▾</span>
            <ul id="service-ul">
              <div className="dd-imgdiv">
                <img id="ddimg" src={navleft} alt="" />
                <p>
                  Transform your ideas into reality with expert solutions,
                  enhancing your presence and elevating your business.
                </p>
              </div>
              <div className="dd">
                <div className="dd-op">
                  {/* <h4>Development</h4> */}
                  <li>
                    <Link to="/Webdevelopment">
                      Web Development <span>→</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Appdevelopment">
                      App Development <span>→</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/AI_ML">
                      AI & ML <span>→</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Wordpress">
                      Wordpress Development <span>→</span>
                    </Link>
                  </li>
                </div>
                <div className="dd-op">
                  {/* <h4>Design</h4> */}
                  <li>
                    <Link to="/UI_UX">
                      UI & UX <span>→</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/VideoEditing">
                      Video Editing <span>→</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/LogoDesign">
                      Logo Designs <span>→</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/SocialMediaMarketingandManagement">
                      Social Media Marketing <span>→</span>
                    </Link>
                  </li>
                </div>
                {/* <div className="dd-op">
                  <h4>AI & ML</h4>
                  <li>
                    Web Development <span>→</span>
                  </li>
                  <li>
                    App Development <span>→</span>
                  </li>
                  <li>
                    App Development <span>→</span>
                  </li>
                  <li>
                    App Development <span>→</span>
                  </li>
                </div> */}
                <div className="dd-op">
                  {/* <h4>AI & ML</h4> */}
                  <li>
                    <Link to="/SEO">
                      SEO <span>→</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Adsense">
                      Google Adsense <span>→</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/DigitalMarketing">
                      Digital Marketing <span>→</span>
                    </Link>
                  </li>
                  {/* <li>
                    App Development <span>→</span>
                  </li> */}
                </div>
                {/* <div className="dd-op">
                  <h4>AI & ML</h4>
                  <li>
                    Web Development <span>→</span>
                  </li>
                  <li>
                    App Development <span>→</span>
                  </li>
                  <li>
                    App Development <span>→</span>
                  </li>
                  <li>
                    App Development <span>→</span>
                  </li>
                </div> */}
              </div>
            </ul>
          </li>
          <li id="services">
            INDUSTRIES<span className="servnavarrow">▾</span>
            <ul id="service-ul">
              <div className="dd-imgdiv">
                <img id="ddimg" src={navindustryleft} alt="" />
                <p>
                  We cater to diverse industries, providing tailored solutions
                  to meet sector-specific needs and challenges.
                </p>
              </div>
              <div className="dd">
                <div className="dd-op dd-ind">
                  <Link to="/Real_Estate">
                    <h2>
                      Real-Estate <span>→</span>
                    </h2>
                  </Link>
                  <Link to="/HealthCare">
                    <h2>
                      HealthCare <span>→</span>
                    </h2>
                  </Link>
                  <Link to="/E_Commerce">
                    <h2>
                      E-Commerce <span>→</span>
                    </h2>
                  </Link>
                  <Link to="/Travel">
                    <h2>
                      Travel <span>→</span>
                    </h2>
                  </Link>
                  <Link to="/Education">
                    <h2>
                      Education <span>→</span>
                    </h2> 
                  </Link>
                  <Link to="/Wellness_Fitness">
                    <h2>
                      Wellness & Fitness <span>→</span>
                    </h2>
                  </Link>
                </div>
              </div>
            </ul>
          </li>
          <li>
            <Link to="/About">ABOUT</Link>
          </li>
          <li>
            <Link id="nav-talk" to="/Contact">
              LET'S TALK <span className="navtalkarrow">→</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
