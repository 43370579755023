import React from "react";
import "./Servmainsec.css";

const Wordpressservmainsec = () => {
  return (
    <>
      <div className="servicemainsectionmain">
        <h6>
          <span>HOME</span>
          <span id="servicesmainpagedot">•</span>WordPress SERVICES
        </h6>
        <h1>WordPress Development</h1>
        <p>
          Looking to integrate a highly customized WordPress solution for your
          business? We provide comprehensive WordPress services to ensure your
          website is modern, responsive, and user-friendly.
        </p>
      </div>
      <div className="servicepagelistmain">
        <div className="servicepagelistmain-top">
          <div className="servicepagelistmain-left">
            <p>
              Our team delivers tailored WordPress solutions, ensuring
              scalability, reliability, and high performance for your business.
            </p>
          </div>
          <div className="servicepagelistmain-right">
            <h1>Transforming User Experience with WordPress</h1>
            <p>
              We specialize in developing WordPress-powered websites that elevate
              the user experience. From intuitive interfaces to seamless
              integrations, our process is designed to meet both user
              expectations and business goals.
            </p>
          </div>
        </div>
        <div className="servicepagelistmain-bottom">
          <div className="servicepagelistmain-bottom1">
            <h2>WordPress Data Insights</h2>
            <p>
              Unlock the full potential of your data with our WordPress-powered
              insights. We utilize tools and plugins to analyze website data,
              uncover trends, and provide actionable insights that drive decision-making and accelerate growth.
            </p>
          </div>
          <div className="servicepagelistmain-bottom2">
            <h2>Custom WordPress Solutions</h2>
            <p>
              We create tailored WordPress solutions that solve complex problems.
              From custom plugins to unique themes, our services are designed to
              meet the challenges faced by enterprises looking to innovate.
            </p>
          </div>
          <div className="servicepagelistmain-bottom3">
            <h2>Enterprise-Level WordPress Systems</h2>
            <p>
              Our enterprise-grade WordPress systems offer scalability and
              flexibility. We develop websites that handle large amounts of content,
              ensuring robust performance across industries, while integrating
              with your existing infrastructure for seamless operations.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wordpressservmainsec;
